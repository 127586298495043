import { useCallback } from 'react';
import { useAuth } from './useAuth';
import { db } from '../lib/firebase';
import { getDB } from '../lib/db';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  doc, 
  getDoc, 
  updateDoc, 
  DocumentSnapshot,
  QuerySnapshot
} from 'firebase/firestore';
import { Application } from '../types/application';
import { useUsers } from './useUsers';
import toast from 'react-hot-toast';

export function useApplications() {
  const { user } = useAuth();
  const { fetchUserName } = useUsers();

  const processApplicationData = async (docSnapshot: DocumentSnapshot): Promise<Application> => {
    const data = { id: docSnapshot.id, ...docSnapshot.data() } as Application;
    
    try {
      // Calculate fulfillment time if application is completed
      if (data.status === 'completed' && data.completedAt) {
        const submissionTime = new Date(data.createdAt).getTime();
        const completionTime = new Date(data.completedAt).getTime();
        data.fulfillmentTime = completionTime - submissionTime;
      }

      // Get agent name if assigned
      if (data.agentId) {
        data.agentName = await fetchUserName(data.agentId);
      } else {
        data.agentName = 'Unassigned';
      }

      // Get customer name
      if (data.userId) {
        data.customerName = await fetchUserName(data.userId);
      }

      return data;
    } catch (error) {
      console.error('Error processing application:', error);
      // Return basic application data if processing fails
      return {
        ...data,
        agentName: data.agentId ? 'Unknown Agent' : 'Unassigned',
        customerName: 'Unknown Customer'
      };
    }
  };

  const processQuerySnapshot = async (snapshot: QuerySnapshot) => {
    return Promise.all(snapshot.docs.map(processApplicationData));
  };

  const fetchUserApplications = useCallback(async () => {
    if (!user) throw new Error('User not authenticated');

    try {
      const q = query(
        collection(db, 'applications'),
        where('userId', '==', user.uid),
        orderBy('updatedAt', 'desc')
      );

      const snapshot = await getDocs(q);
      const applications = await processQuerySnapshot(snapshot);

      // Store in IndexedDB for offline access
      const idb = await getDB();
      await Promise.all(
        applications.map(app =>
          idb.put('applications', {
            id: app.id,
            lastSync: new Date().toISOString(),
            data: app
          })
        )
      );

      return applications;
    } catch (error: any) {
      console.error('Error fetching applications:', error);
      
      // Try to load from IndexedDB if offline
      if (!navigator.onLine) {
        try {
          const idb = await getDB();
          const offlineApps = await idb.getAll('applications');
          if (offlineApps.length > 0) {
            toast.success('Loaded applications from cache');
            return offlineApps.map(app => app.data);
          }
        } catch (idbError) {
          console.error('Error loading from IndexedDB:', idbError);
        }
      }

      toast.error('Failed to load applications. Please check your connection.');
      return [];
    }
  }, [user, fetchUserName]);

  const fetchApplicationById = useCallback(async (id: string) => {
    if (!user) throw new Error('User not authenticated');

    try {
      const appRef = doc(db, 'applications', id);
      const appSnap = await getDoc(appRef);
      
      if (!appSnap.exists()) {
        throw new Error('Application not found');
      }

      const application = await processApplicationData(appSnap);

      // Store in IndexedDB
      const idb = await getDB();
      await idb.put('applications', {
        id: application.id,
        lastSync: new Date().toISOString(),
        data: application
      });

      return application;
    } catch (error: any) {
      console.error('Error fetching application:', error);
      
      // Try to load from IndexedDB if offline
      if (!navigator.onLine) {
        try {
          const idb = await getDB();
          const offlineApp = await idb.get('applications', id);
          if (offlineApp) {
            return offlineApp.data;
          }
        } catch (idbError) {
          console.error('Error loading from IndexedDB:', idbError);
        }
      }

      if (error.message === 'Application not found') {
        throw new Error('Application not found');
      }
      throw new Error('Failed to load application details. Please try again.');
    }
  }, [user, processApplicationData]);

  const updateApplication = useCallback(async (id: string, updates: Partial<Application>) => {
    if (!user) throw new Error('User not authenticated');

    try {
      const now = new Date().toISOString();
      const updateData = {
        ...updates,
        updatedAt: now,
        lastActionAt: now,
        lastActionBy: user.uid
      };

      // If marking as completed, add completion time
      if (updates.status === 'completed') {
        updateData.completedAt = now;
      }

      const appRef = doc(db, 'applications', id);
      await updateDoc(appRef, updateData);

      // Update IndexedDB
      const idb = await getDB();
      const existingApp = await idb.get('applications', id);
      if (existingApp) {
        await idb.put('applications', {
          ...existingApp,
          lastSync: now,
          data: {
            ...existingApp.data,
            ...updateData
          }
        });
      }

      toast.success('Application updated successfully');
    } catch (error: any) {
      console.error('Error updating application:', error);
      if (error.code === 'permission-denied') {
        throw new Error('You do not have permission to update this application');
      }
      throw new Error('Failed to update application. Please try again.');
    }
  }, [user]);

  return {
    fetchUserApplications,
    fetchApplicationById,
    updateApplication
  };
}