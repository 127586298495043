export const CONTACT_INFO = {
  phone: "+1 704 565 9947",
  phoneRaw: "17045659947", // For href="tel:" links
  email: "support@blitzapply.com",
  socialMedia: {
    twitter: "https://twitter.com/blitzapply",
    linkedin: "https://linkedin.com/company/blitzapply",
    facebook: "https://facebook.com/blitzapply"
  },
  address: {
    street: "", // Add if needed
    city: "",   // Add if needed
    state: "",  // Add if needed
    zip: ""     // Add if needed
  }
}; 