import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { AlertTriangle } from 'lucide-react';
import { UserProfile } from '../../hooks/useAuth';

interface ConfirmDeleteAgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (password: string) => Promise<void>;
  agent: UserProfile & { id: string };
}

export function ConfirmDeleteAgentModal({
  isOpen,
  onClose,
  onConfirm,
  agent
}: ConfirmDeleteAgentModalProps) {
  const [confirmText, setConfirmText] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const expectedText = agent.email;

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onConfirm(password);
    } finally {
      setLoading(false);
      setPassword('');
      setConfirmText('');
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="flex items-center text-lg font-medium leading-6 text-gray-900"
                >
                  <AlertTriangle className="h-6 w-6 text-red-500 mr-2" />
                  Delete Agent
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    This action cannot be undone. This will permanently delete the agent account for:
                  </p>
                  <p className="mt-2 text-sm font-medium text-gray-900">
                    {agent.firstName} {agent.lastName} ({agent.email})
                  </p>
                  <div className="mt-4">
                    <p className="text-sm text-gray-900 font-medium">
                      Please type <span className="font-mono">{agent.email}</span> to confirm:
                    </p>
                    <input
                      type="text"
                      className="mt-2 px-4 h-12 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 text-gray-900"
                      value={confirmText}
                      onChange={(e) => setConfirmText(e.target.value)}
                      placeholder="Type agent's email to confirm"
                    />
                  </div>
                  <div className="mt-4">
                    <p className="text-sm text-gray-900 font-medium">
                      Enter agent's current password:
                    </p>
                    <input
                      type="password"
                      className="mt-2 px-4 h-12 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 text-gray-900"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter agent's password"
                    />
                  </div>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={handleConfirm}
                    disabled={confirmText !== expectedText || !password || loading}
                  >
                    {loading ? 'Deleting...' : 'Delete Agent'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}