
interface ProfilePictureProps {
  photoURL: string | null;
  firstName: string;
  lastName: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

const sizeClasses = {
  sm: 'h-10 w-10',
  md: 'h-16 w-16',
  lg: 'h-20 w-20',
  xl: 'h-32 w-32'
};

export function ProfilePicture({ photoURL, firstName, lastName, size = 'md', className = '' }: ProfilePictureProps) {
  const sizeClass = sizeClasses[size];
  const initials = `${firstName[0]}${lastName[0]}`;

  const handleClick = () => {
    if (photoURL) {
      window.open(photoURL, '_blank');
    }
  };

  return (
    <>
      {photoURL ? (
        <img
          src={photoURL}
          alt={`${firstName} ${lastName}`}
          className={`${sizeClass} rounded-full object-cover cursor-pointer ${className}`}
          onClick={handleClick}
          title="Click to view full image"
        />
      ) : (
        <div className={`${sizeClass} rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center ${className}`}>
          <span className="text-2xl text-gray-500 dark:text-gray-400">
            {initials}
          </span>
        </div>
      )}
    </>
  );
} 