import { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { UserProfile } from '../types/user';
import { AgentsTable } from '../components/tables/AgentsTable';
import { CustomersTable } from '../components/tables/CustomersTable';
import { Link } from 'react-router-dom';
import { Plus, FileText, Play, Clock, CheckCircle2, XCircle, AlertCircle, AlertTriangle, RotateCw, DollarSign } from 'lucide-react';
import { AddAgentForm } from '../components/forms/AddAgentForm';
import { Application } from '../types/application';
import toast from 'react-hot-toast';

interface DashboardStats {
  total: number;
  submitted: number;
  inProgress: number;
  pending: number;
  completed: number;
  cancelled: number;
  avgFulfillmentTime: number;
  resubmitted: number;
}

const initialStats: DashboardStats = {
  total: 0,
  submitted: 0,
  inProgress: 0,
  pending: 0,
  completed: 0,
  cancelled: 0,
  avgFulfillmentTime: 0,
  resubmitted: 0
};

export function AdminDashboard() {
  const [stats, setStats] = useState<DashboardStats>(initialStats);
  const [agents, setAgents] = useState<(UserProfile & { id: string })[]>([]);
  const [customers, setCustomers] = useState<(UserProfile & { id: string })[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddAgentForm, setShowAddAgentForm] = useState(false);

  const loadDashboardData = async () => {
    try {
      setLoading(true);
      await Promise.all([
        loadApplicationStats(),
        loadUsers()
      ]);
    } catch (error) {
      console.error('Error loading dashboard data:', error);
      toast.error('Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  const loadApplicationStats = async () => {
    try {
      const applicationsRef = collection(db, 'applications');
      const snapshot = await getDocs(applicationsRef);
      const applications = snapshot.docs.map(doc => ({ 
        id: doc.id, 
        ...doc.data() 
      })) as Application[];

      // Calculate statistics
      const total = applications.length;
      const submitted = applications.filter(app => app.status === 'submitted').length;
      const inProgress = applications.filter(app => app.status === 'in_progress').length;
      const pending = applications.filter(app => app.status === 'pending').length;
      const completed = applications.filter(app => app.status === 'completed').length;
      const cancelled = applications.filter(app => app.status === 'cancelled').length;

      // Calculate average fulfillment time
      const completedApps = applications.filter(app => app.status === 'completed' && app.completedAt);
      const totalFulfillmentTime = completedApps.reduce((acc, app) => {
        const fulfillmentTime = new Date(app.completedAt!).getTime() - new Date(app.createdAt).getTime();
        return acc + fulfillmentTime;
      }, 0);
      const avgFulfillmentTime = completedApps.length > 0 ? totalFulfillmentTime / completedApps.length : 0;

      setStats({
        total,
        submitted,
        inProgress,
        pending,
        completed,
        cancelled,
        avgFulfillmentTime,
        resubmitted: 0
      });
    } catch (error) {
      console.error('Error loading application stats:', error);
      toast.error('Failed to load application statistics');
    }
  };

  const loadUsers = async () => {
    try {
      setLoading(true);
      const usersQuery = query(collection(db, 'users'));
      const querySnapshot = await getDocs(usersQuery);
      const usersList = querySnapshot.docs.map(doc => {
        const data = doc.data() as UserProfile;
        return {
          ...data,
          id: doc.id
        };
      });

      // Separate users by type
      const agentsList = usersList
        .filter(user => user.userType === 'agent')
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      const customersList = usersList
        .filter(user => user.userType === 'customer')
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      setAgents(agentsList);
      setCustomers(customersList);
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDashboardData();
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Admin Dashboard</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Monitor system activity and manage users
        </p>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <Link
          to="/admin/payments"
          className="flex items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow hover:shadow-md transition-shadow"
        >
          <DollarSign className="h-6 w-6 text-indigo-600 dark:text-indigo-400 mr-3" />
          <div>
            <h3 className="font-medium text-gray-900 dark:text-white">Agent Payments</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">Manage agent compensation</p>
          </div>
        </Link>
        
        {/* ... other quick action links ... */}
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">Applications Overview</h2>
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <Link 
              to="/admin/applications" 
              className="bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 p-4 hover:shadow-lg dark:hover:bg-gray-700 transition-all duration-200"
            >
              <div className="flex items-center space-x-3">
                <FileText className="h-7 w-7 text-indigo-500 dark:text-indigo-400 flex-shrink-0" />
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Applications</h3>
                  <p className="text-3xl font-semibold text-indigo-500 dark:text-indigo-400 mt-1">{stats.total}</p>
                </div>
              </div>
            </Link>
            <Link 
              to="/admin/applications?status=submitted" 
              className="bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 p-4 hover:shadow-lg dark:hover:bg-gray-700 transition-all duration-200"
            >
              <div className="flex items-center space-x-3">
                <AlertCircle className="h-7 w-7 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Submitted</h3>
                  <p className="text-3xl font-semibold text-blue-500 dark:text-blue-400 mt-1">{stats.submitted}</p>
                </div>
              </div>
            </Link>
            <Link 
              to="/admin/applications?status=in_progress" 
              className="bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 p-4 hover:shadow-lg dark:hover:bg-gray-700 transition-all duration-200"
            >
              <div className="flex items-center space-x-3">
                <Play className="h-7 w-7 text-yellow-500 dark:text-yellow-400 flex-shrink-0" />
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">In Progress</h3>
                  <p className="text-3xl font-semibold text-yellow-500 dark:text-yellow-400 mt-1">{stats.inProgress}</p>
                </div>
              </div>
            </Link>
            <Link 
              to="/admin/applications?status=pending" 
              className="bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 p-4 hover:shadow-lg dark:hover:bg-gray-700 transition-all duration-200"
            >
              <div className="flex items-center space-x-3">
                <AlertTriangle className="h-7 w-7 text-orange-500 dark:text-orange-400 flex-shrink-0" />
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Pending Info</h3>
                  <p className="text-3xl font-semibold text-orange-500 dark:text-orange-400 mt-1">{stats.pending}</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 p-4">
              <div className="flex items-center space-x-3">
                <Clock className="h-7 w-7 text-indigo-500 dark:text-indigo-400 flex-shrink-0" />
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Average Fulfillment Time</h3>
                  <p className="text-3xl font-semibold text-indigo-500 dark:text-indigo-400 mt-1">
                    {Math.round(stats.avgFulfillmentTime / (1000 * 60 * 60 * 24))} days
                  </p>
                </div>
              </div>
            </div>

            <Link 
              to="/admin/applications?status=completed" 
              className="bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 p-4 hover:shadow-lg dark:hover:bg-gray-700 transition-all duration-200"
            >
              <div className="flex items-center space-x-3">
                <CheckCircle2 className="h-7 w-7 text-green-500 dark:text-green-400 flex-shrink-0" />
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Completed</h3>
                  <p className="text-3xl font-semibold text-green-500 dark:text-green-400 mt-1">{stats.completed}</p>
                </div>
              </div>
            </Link>
            <Link 
              to="/admin/applications?status=cancelled" 
              className="bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 p-4 hover:shadow-lg dark:hover:bg-gray-700 transition-all duration-200"
            >
              <div className="flex items-center space-x-3">
                <XCircle className="h-7 w-7 text-red-500 dark:text-red-400 flex-shrink-0" />
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Cancelled</h3>
                  <p className="text-3xl font-semibold text-red-500 dark:text-red-400 mt-1">{stats.cancelled}</p>
                </div>
              </div>
            </Link>
            <Link 
              to="/admin/applications?status=resubmitted" 
              className="bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 p-4 hover:shadow-lg dark:hover:bg-gray-700 transition-all duration-200"
            >
              <div className="flex items-center space-x-3">
                <RotateCw className="h-7 w-7 text-purple-500 dark:text-purple-400 flex-shrink-0" />
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Resubmitted</h3>
                  <p className="text-3xl font-semibold text-purple-500 dark:text-purple-400 mt-1">{stats.resubmitted || 0}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-12 space-y-12">
        <div>
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Agents</h2>
              <Link
                to="/admin/agents"
                className="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300"
              >
                View All
              </Link>
            </div>
            <button
              onClick={() => setShowAddAgentForm(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              <Plus className="h-5 w-5 mr-2" />
              Add New Agent
            </button>
          </div>

          {showAddAgentForm && (
            <div className="mb-6">
              <AddAgentForm
                onSuccess={() => {
                  setShowAddAgentForm(false);
                  loadUsers();
                }}
                onCancel={() => setShowAddAgentForm(false)}
              />
            </div>
          )}

          <AgentsTable 
            agents={agents}
            onAgentUpdated={loadUsers}
            pageSize={10}
          />
        </div>

        <div>
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Customers</h2>
              <Link
                to="/admin/customers"
                className="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300"
              >
                View All
              </Link>
            </div>
          </div>

          <CustomersTable 
            customers={customers}
            loading={loading}
            onCustomerUpdated={loadUsers}
            pageSize={5}
          />
        </div>
      </div>
    </div>
  );
}