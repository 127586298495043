import { useRef, useState } from 'react';
import { Upload, Trash2 } from 'lucide-react';
import { FaRegFilePdf } from 'react-icons/fa6';
import toast from 'react-hot-toast';

interface ResumeUploadProps {
  resumeURL: string | null;
  onUpload: (file: File) => Promise<void>;
  onDelete: () => Promise<void>;
}

export function ResumeUpload({ resumeURL, onUpload, onDelete }: ResumeUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    
    const file = e.target.files[0];
    if (file.type !== 'application/pdf') {
      toast.error('Please upload a PDF file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error('File size must be less than 5MB');
      return;
    }
    
    try {
      setUploading(true);
      await onUpload(file);
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // Extract just the filename from the full URL
  const getFileName = (url: string) => {
    try {
      const decodedUrl = decodeURIComponent(url);
      return decodedUrl.split('/').pop()?.split('?')[0] || '';
    } catch (error) {
      return 'Resume.pdf';
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mb-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Resume</h2>
        <div className="flex space-x-2">
          <button
            onClick={() => fileInputRef.current?.click()}
            disabled={uploading}
            className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
          >
            <Upload className="h-4 w-4 mr-2" />
            Upload Resume (PDF)
          </button>
          {resumeURL && (
            <button
              onClick={onDelete}
              disabled={uploading}
              className="inline-flex items-center px-4 py-2 border border-red-300 dark:border-red-500 rounded-md shadow-sm text-sm font-medium text-red-700 dark:text-red-400 bg-white dark:bg-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 disabled:opacity-50"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete
            </button>
          )}
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="application/pdf"
        onChange={handleFileChange}
      />
      {uploading && (
        <div className="mb-4 text-sm text-gray-600 dark:text-gray-400">
          Uploading resume...
        </div>
      )}
      {resumeURL && !uploading && (
        <a
          href={resumeURL}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-2 p-3 bg-gray-50 dark:bg-gray-700 rounded-md hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-200 group"
        >
          <FaRegFilePdf className="h-6 w-6 text-red-500 dark:text-red-400 group-hover:text-red-600 dark:group-hover:text-red-300" />
          <span className="text-sm font-medium text-gray-900 dark:text-white group-hover:text-indigo-600 dark:group-hover:text-indigo-400">
            {getFileName(resumeURL)}
          </span>
        </a>
      )}
    </div>
  );
}