import { Link } from 'react-router-dom';
import { Briefcase, Users, Globe2, Zap } from 'lucide-react';

const positions = [
  {
    id: 1,
    title: 'Senior Application Agent',
    department: 'Operations',
    location: 'Remote',
    type: 'Full-time',
    description: 'Lead a team of application agents and help develop best practices for application processing.'
  },
  {
    id: 2,
    title: 'Full Stack Developer',
    department: 'Engineering',
    location: 'Remote',
    type: 'Full-time',
    description: 'Build and maintain our core application platform using React, TypeScript, and Firebase.'
  },
  {
    id: 3,
    title: 'Customer Success Manager',
    department: 'Support',
    location: 'Remote',
    type: 'Full-time',
    description: 'Help our customers succeed in their job search journey and maximize their use of BlitzApply.'
  }
];

const perks = [
  {
    name: 'Remote-First Culture',
    description: 'Work from anywhere in the world. We believe in hiring the best talent, regardless of location.',
    icon: Globe2
  },
  {
    name: 'Competitive Compensation',
    description: 'Attractive salary packages with equity options and comprehensive benefits.',
    icon: Zap
  },
  {
    name: 'Growth Opportunities',
    description: 'Regular training, mentorship, and clear career progression paths.',
    icon: Users
  },
  {
    name: 'Meaningful Impact',
    description: 'Help thousands of job seekers land their dream jobs and advance their careers.',
    icon: Briefcase
  }
];

export function Careers() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
            Join Our Mission
          </h1>
          <p className="mt-5 max-w-xl mx-auto text-xl text-gray-500 dark:text-gray-400">
            Help us revolutionize the job application process and make job hunting easier for everyone.
          </p>
        </div>

        {/* Perks Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white text-center">
            Why Work With Us
          </h2>
          <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {perks.map((perk) => (
              <div key={perk.name} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
                <div className="text-indigo-600 dark:text-indigo-400">
                  <perk.icon className="h-8 w-8" />
                </div>
                <div className="mt-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    {perk.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {perk.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Open Positions */}
        <div className="mt-24">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white text-center">
            Open Positions
          </h2>
          <div className="mt-12 space-y-4">
            {positions.map((position) => (
              <div
                key={position.id}
                className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-6"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                      {position.title}
                    </h3>
                    <div className="mt-1 flex items-center space-x-4">
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {position.department}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        • {position.location}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        • {position.type}
                      </span>
                    </div>
                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      {position.description}
                    </p>
                  </div>
                  <Link
                    to="/contact"
                    className="flex-shrink-0 bg-indigo-600 dark:bg-indigo-500 hover:bg-indigo-700 dark:hover:bg-indigo-600 text-white text-sm font-medium py-2 px-4 rounded-md"
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-24 text-center">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            Don't see a position that fits?
          </h2>
          <p className="mt-4 text-lg text-gray-500 dark:text-gray-400">
            We're always looking for talented individuals to join our team.
          </p>
          <Link
            to="/contact"
            className="mt-8 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 dark:bg-indigo-500 hover:bg-indigo-700 dark:hover:bg-indigo-600"
          >
            Send Us Your Resume
          </Link>
        </div>
      </div>
    </div>
  );
} 