import { Pencil, CheckCircle2 } from 'lucide-react';

interface ProfileFormProps {
  firstName: string;
  lastName: string;
  email: string | null | undefined;
  isVerified: boolean;
  editMode: boolean;
  loading: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  onChange: (field: 'firstName' | 'lastName', value: string) => void;
  onVerify: () => Promise<void>;
  onDeleteClick: () => void;
}

export function ProfileForm({
  firstName,
  lastName,
  email,
  isVerified,
  editMode,
  loading,
  onEdit,
  onCancel,
  onSubmit,
  onChange,
  onVerify,
  onDeleteClick
}: ProfileFormProps) {
  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Personal Information</h2>
        {!editMode && (
          <button
            type="button"
            onClick={onEdit}
            className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-200"
          >
            Edit Profile
            <Pencil className="ml-2 h-4 w-4" />
          </button>
        )}
      </div>

      <form onSubmit={onSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              disabled={!editMode}
              className={`mt-1 px-4 h-12 block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                ${editMode 
                  ? 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white' 
                  : 'bg-gray-100 dark:bg-gray-700 border-transparent text-gray-900 dark:text-white'}`}
              value={firstName}
              onChange={(e) => onChange('firstName', e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              disabled={!editMode}
              className={`mt-1 px-4 h-12 block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                ${editMode 
                  ? 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white' 
                  : 'bg-gray-100 dark:bg-gray-700 border-transparent text-gray-900 dark:text-white'}`}
              value={lastName}
              onChange={(e) => onChange('lastName', e.target.value)}
            />
          </div>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Email
          </label>
          <div className="mt-1 flex items-center">
            <input
              type="email"
              id="email"
              disabled
              className="px-4 h-12 block w-full rounded-md border-transparent bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white"
              value={email || ''}
            />
            {isVerified ? (
              <CheckCircle2 className="h-5 w-5 text-green-500 ml-2" />
            ) : (
              <button
                type="button"
                onClick={onVerify}
                className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Verify
              </button>
            )}
          </div>
        </div>

        {editMode && (
          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50"
            >
              Save Changes
            </button>
          </div>
        )}

        {!editMode && (
          <div className="mt-6 border-t border-gray-200 dark:border-gray-700 pt-6">
            <button
              type="button"
              onClick={onDeleteClick}
              className="text-sm text-red-600 dark:text-red-400 hover:text-red-500 dark:hover:text-red-300"
            >
              Delete Account
            </button>
          </div>
        )}
      </form>
    </div>
  );
}