import { ExternalLink } from 'lucide-react';
import { Application } from '../../../types/application';
import { formatDate } from '../../../utils/date';

interface ApplicationDetailsProps {
  application: Application;
}

export function ApplicationDetails({ application }: ApplicationDetailsProps) {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
      <div>
        <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Job URL</dt>
        <dd className="mt-1">
          <a
            href={application.jobUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
          >
            View Job Posting
            <ExternalLink className="h-4 w-4 ml-1" />
          </a>
        </dd>
      </div>

      <div>
        <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Submitted</dt>
        <dd className="mt-1 text-sm text-gray-600 dark:text-gray-100">
          {formatDate(application.createdAt)}
        </dd>
      </div>

      <div>
        <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Last Updated</dt>
        <dd className="mt-1 text-sm text-gray-600 dark:text-gray-100">
          {formatDate(application.updatedAt)}
        </dd>
      </div>

      {application.notes && (
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Notes</dt>
          <dd className="mt-1 text-sm text-gray-600 dark:text-gray-100 whitespace-pre-wrap">
            {application.notes}
          </dd>
        </div>
      )}
    </dl>
  );
}