import { Link } from 'react-router-dom';
import { Target, Zap, Shield, Users } from 'lucide-react';

const values = [
  {
    name: 'Efficiency First',
    description: 'We believe in maximizing productivity and minimizing wasted effort in the job application process.',
    icon: Zap
  },
  {
    name: 'User Privacy',
    description: 'Your data security and privacy are our top priorities. We maintain the highest standards of data protection.',
    icon: Shield
  },
  {
    name: 'Quality Service',
    description: 'Our agents are trained professionals who ensure each application receives thorough attention and care.',
    icon: Target
  },
  {
    name: 'Community Focus',
    description: 'We\'re building a community of job seekers and helping them achieve their career goals.',
    icon: Users
  }
];

const stats = [
  { label: 'Applications Processed', value: '100,000+' },
  { label: 'Success Rate', value: '92%' },
  { label: 'Active Agents', value: '50+' },
  { label: 'Happy Customers', value: '10,000+' }
];

export function About() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
            About BlitzApply
          </h1>
          <p className="mt-5 max-w-xl mx-auto text-xl text-gray-500 dark:text-gray-400">
            We're on a mission to revolutionize the job application process and help people land their dream jobs faster.
          </p>
        </div>

        {/* Story Section */}
        <div className="mt-20">
          <div className="prose prose-lg dark:prose-invert mx-auto">
            <p>
              BlitzApply was born from a simple observation: job seekers spend countless hours filling out repetitive application forms instead of focusing on what really matters - preparing for interviews and improving their skills.
            </p>
            <p>
              Our platform connects job seekers with expert application agents who handle the time-consuming process of filling out job applications, allowing candidates to focus on more valuable aspects of their job search.
            </p>
            <p>
              What started as a small team of passionate individuals has grown into a thriving platform that has helped thousands of job seekers streamline their application process and land their dream jobs.
            </p>
          </div>
        </div>

        {/* Stats Section */}
        <div className="mt-20">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.label} className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base leading-7 text-gray-500 dark:text-gray-400">{stat.label}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Values Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white text-center">
            Our Values
          </h2>
          <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {values.map((value) => (
              <div key={value.name} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
                <div className="text-indigo-600 dark:text-indigo-400">
                  <value.icon className="h-8 w-8" />
                </div>
                <div className="mt-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    {value.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {value.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-24 text-center">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            Ready to Get Started?
          </h2>
          <p className="mt-4 text-lg text-gray-500 dark:text-gray-400">
            Join thousands of job seekers who are already using BlitzApply.
          </p>
          <div className="mt-8 flex justify-center gap-x-4">
            <Link
              to="/register"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 dark:bg-indigo-500 hover:bg-indigo-700 dark:hover:bg-indigo-600"
            >
              Get Started
            </Link>
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-5 py-3 border border-gray-300 dark:border-gray-600 text-base font-medium rounded-md text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 