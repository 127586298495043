import { forwardRef } from 'react';
import { cn } from '../../utils/cn';
import { Check } from 'lucide-react';

export interface CheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  className?: string;
  disabled?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, checked, onChange, disabled }, ref) => (
    <div
      className={cn(
        'h-4 w-4 rounded border border-gray-300 dark:border-gray-600',
        'flex items-center justify-center',
        'cursor-pointer',
        checked && 'bg-indigo-600 border-indigo-600',
        disabled && 'opacity-50 cursor-not-allowed',
        className
      )}
      onClick={() => !disabled && onChange?.(!checked)}
    >
      {checked && <Check className="h-3 w-3 text-white" />}
      <input
        type="checkbox"
        className="sr-only"
        ref={ref}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.checked)}
      />
    </div>
  )
);

Checkbox.displayName = 'Checkbox'; 