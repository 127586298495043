import { useCallback } from 'react';
import { useAuth } from './useAuth';
import { db } from '../lib/firebase';
import { collection, doc, writeBatch, increment, updateDoc, getDoc } from 'firebase/firestore';
import { Application, ApplicationStatus } from '../types/application';
import toast from 'react-hot-toast';

export function useApplicationActions() {
  const { user } = useAuth();

  const submitApplication = useCallback(async (data: Omit<Application, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (!user) {
      throw new Error('User not authenticated');
    }

    const batch = writeBatch(db);
    const now = new Date().toISOString();

    // Create application document
    const applicationRef = doc(collection(db, 'applications'));
    const applicationData = {
      ...data,
      createdAt: now,
      updatedAt: now,
      lastActionAt: now,
      lastActionBy: user.uid,
      comments: []
    };

    batch.set(applicationRef, applicationData);

    // Update user's credits
    const userRef = doc(db, 'users', user.uid);
    batch.update(userRef, {
      credits: increment(-3)
    });

    try {
      await batch.commit();
      toast.success('Application submitted successfully');
      return {
        id: applicationRef.id,
        ...applicationData
      };
    } catch (error: any) {
      console.error('Error submitting application:', error);
      if (error.code === 'permission-denied') {
        throw new Error('Insufficient credits or permissions');
      }
      throw new Error('Failed to submit application. Please try again.');
    }
  }, [user]);

  const updateApplicationStatus = async (
    applicationId: string, 
    status: ApplicationStatus,
    notes?: string
  ) => {
    if (!user) return;

    try {
      const appRef = doc(db, 'applications', applicationId);
      const appSnap = await getDoc(appRef);
      
      if (!appSnap.exists()) {
        throw new Error('Application not found');
      }

      const appData = appSnap.data() as Application;
      const updateData: Partial<Application> = {
        status,
        updatedAt: new Date().toISOString(),
        lastActionBy: user.uid,
        lastActionAt: new Date().toISOString()
      };

      if (status === 'completed') {
        updateData.completedAt = new Date().toISOString();
        updateData.fulfillmentTime = Date.now() - new Date(appData.createdAt).getTime();
        updateData.paymentStatus = 'pending';
      }

      if (notes) {
        updateData.notes = notes;
      }

      await updateDoc(appRef, updateData);
      toast.success('Application status updated successfully');
    } catch (error) {
      console.error('Error updating application status:', error);
      toast.error('Failed to update application status');
    }
  };

  return {
    submitApplication,
    updateApplicationStatus
  };
}