import { Link } from 'react-router-dom';

export function Cookies() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Cookie Policy</h1>
          <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">1. What Are Cookies</h2>
              <p className="text-gray-600 dark:text-gray-400">
                Cookies are small text files that are placed on your computer or mobile device when you visit our website. They are widely used to make websites work more efficiently and provide useful information to website owners.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">2. How We Use Cookies</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  We use cookies for the following purposes:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>Authentication and security (Essential)</li>
                  <li>User preferences and settings (Functional)</li>
                  <li>Analytics and performance (Performance)</li>
                  <li>Service personalization (Targeting)</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">3. Types of Cookies We Use</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">3.1 Essential Cookies</h3>
                  <p className="text-gray-600 dark:text-gray-400 mb-2">
                    Required for the website to function properly. They include:
                  </p>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Authentication cookies</li>
                    <li>Security-related cookies</li>
                    <li>Session management</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">3.2 Functional Cookies</h3>
                  <p className="text-gray-600 dark:text-gray-400 mb-2">
                    Help us remember your preferences and settings:
                  </p>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Language preferences</li>
                    <li>Dark/light mode settings</li>
                    <li>Previously viewed items</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">3.3 Analytics Cookies</h3>
                  <p className="text-gray-600 dark:text-gray-400 mb-2">
                    Help us understand how visitors interact with our website:
                  </p>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Pages visited</li>
                    <li>Time spent on site</li>
                    <li>Error encounters</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">4. Managing Cookies</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  You can control and manage cookies in various ways:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>Browser settings to block or delete cookies</li>
                  <li>Our cookie consent banner settings</li>
                  <li>Third-party opt-out mechanisms</li>
                </ul>
                <p className="text-gray-600 dark:text-gray-400 mt-4">
                  Please note that blocking essential cookies may affect website functionality.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">5. Third-Party Cookies</h2>
              <p className="text-gray-600 dark:text-gray-400">
                Some cookies are placed by third-party services that appear on our pages. We use third-party cookies for:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2 mt-3">
                <li>Analytics (Google Analytics)</li>
                <li>Authentication (Firebase)</li>
                <li>Performance monitoring</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">6. Contact Us</h2>
              <p className="text-gray-600 dark:text-gray-400">
                If you have questions about our Cookie Policy, please{' '}
                <Link to="/contact" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  contact us
                </Link>.
              </p>
            </section>

            <div className="mt-12 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                This Cookie Policy should be read alongside our{' '}
                <Link to="/privacy" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link to="/terms" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  Terms of Service
                </Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 