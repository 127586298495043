import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string | JSX.Element;
}

interface FAQCategory {
  title: string;
  items: FAQItem[];
}

export function FAQ() {
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  const toggleItem = (categoryIndex: number, itemIndex: number) => {
    const key = `${categoryIndex}-${itemIndex}`;
    setOpenItems(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const faqCategories: FAQCategory[] = [
    {
      title: "Getting Started",
      items: [
        {
          question: "What is BlitzApply?",
          answer: "BlitzApply is a professional job application service that helps streamline your job search process. We assist in preparing and submitting job applications efficiently, saving you time and increasing your chances of success."
        },
        {
          question: "How do I create an account?",
          answer: (
            <div>
              Creating an account is easy! Simply:
              <ol className="list-decimal ml-5 mt-2 space-y-1">
                <li>Click the "Sign In / Register" button</li>
                <li>Fill in your basic information</li>
                <li>Verify your email address</li>
                <li>Complete your profile</li>
              </ol>
            </div>
          )
        },
        {
          question: "Is BlitzApply free to use?",
          answer: "BlitzApply operates on a credit-based system. New users receive 50 free credits upon registration. Additional credits can be purchased through our various pricing plans. Visit our Credits page for more information."
        }
      ]
    },
    {
      title: "Credits & Pricing",
      items: [
        {
          question: "How do credits work?",
          answer: "Credits (CP) are used to submit job applications through our platform. Each application typically costs 3-4 credits, depending on the complexity. You can view your credit balance in your dashboard and purchase more when needed."
        },
        {
          question: "What are the pricing plans?",
          answer: (
            <div>
              We offer three main pricing plans:
              <ul className="list-disc ml-5 mt-2 space-y-1">
                <li>Starter Pack: $29 for 10 credits (3-4 applications)</li>
                <li>Pro Pack: $79 for 30 credits (10 applications)</li>
                <li>Enterprise Pack: $199 for 100 credits (33+ applications)</li>
              </ul>
              <p className="mt-2">
                Visit our <Link to="/pricing" className="text-indigo-600 dark:text-indigo-400 hover:underline">Pricing page</Link> for more details.
              </p>
            </div>
          )
        },
        {
          question: "Do credits expire?",
          answer: "No, your credits never expire. Once purchased, they remain in your account until used."
        }
      ]
    },
    {
      title: "Application Process",
      items: [
        {
          question: "How does the application process work?",
          answer: (
            <div>
              The application process follows these steps:
              <ol className="list-decimal ml-5 mt-2 space-y-1">
                <li>Create a job profile with the position details</li>
                <li>Upload your resume and any additional documents</li>
                <li>Submit the application using your credits</li>
                <li>Our agents review and process your application</li>
                <li>Track the status in your dashboard</li>
                <li>Receive notifications about application updates</li>
              </ol>
            </div>
          )
        },
        {
          question: "How long does processing take?",
          answer: "Most applications are processed within 24-48 hours. Priority processing is available for Pro and Enterprise pack users."
        },
        {
          question: "Can I edit my application after submission?",
          answer: "Once an application is submitted, you cannot edit it directly. However, you can communicate with your assigned agent through the comments section to make any necessary adjustments."
        }
      ]
    },
    {
      title: "Account & Security",
      items: [
        {
          question: "How secure is my information?",
          answer: (
            <div>
              We take security seriously and implement multiple measures to protect your data:
              <ul className="list-disc ml-5 mt-2 space-y-1">
                <li>End-to-end encryption for sensitive data</li>
                <li>Regular security audits</li>
                <li>Compliance with GDPR and privacy regulations</li>
                <li>Secure payment processing</li>
              </ul>
              <p className="mt-2">
                Read our <Link to="/privacy" className="text-indigo-600 dark:text-indigo-400 hover:underline">Privacy Policy</Link> for more details.
              </p>
            </div>
          )
        },
        {
          question: "Can I delete my account?",
          answer: "Yes, you can delete your account at any time through your profile settings. This will permanently remove all your personal information from our system in accordance with our privacy policy."
        },
        {
          question: "How do I reset my password?",
          answer: "You can reset your password by clicking the 'Forgot Password' link on the login page. We'll send you an email with instructions to create a new password."
        }
      ]
    },
    {
      title: "Support",
      items: [
        {
          question: "How can I contact support?",
          answer: (
            <div>
              There are several ways to reach our support team:
              <ul className="list-disc ml-5 mt-2 space-y-1">
                <li>Email: support@blitzapply.com</li>
                <li>Phone: +1 (555) 123-4567</li>
                <li>Contact form on our website</li>
              </ul>
              <p className="mt-2">
                Visit our <Link to="/contact" className="text-indigo-600 dark:text-indigo-400 hover:underline">Contact page</Link> for more information.
              </p>
            </div>
          )
        },
        {
          question: "What are the support hours?",
          answer: "Our support team is available Monday through Friday, 9 AM to 6 PM EST. Enterprise users have access to extended support hours."
        },
        {
          question: "Do you offer refunds?",
          answer: "We offer refunds of credits on cancelled / incorrect / incomplete applications within 30 days of purchase. Please contact our support team to process a refund request."
        }
      ]
    }
  ];

  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            Find answers to common questions about BlitzApply's services and features.
          </p>
        </div>

        <div className="space-y-8">
          {faqCategories.map((category, categoryIndex) => (
            <div key={categoryIndex} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white p-6 bg-gray-200 dark:bg-gray-600 border-b border-gray-200 dark:border-gray-700">
                {category.title}
              </h2>
              <div className="divide-y divide-gray-200 dark:divide-gray-700">
                {category.items.map((item, itemIndex) => {
                  const key = `${categoryIndex}-${itemIndex}`;
                  const isOpen = openItems[key];

                  return (
                    <div key={itemIndex} className="p-6">
                      <button
                        onClick={() => toggleItem(categoryIndex, itemIndex)}
                        className="flex justify-between items-start w-full text-left"
                      >
                        <span className="text-lg font-medium text-gray-900 dark:text-white">
                          {item.question}
                        </span>
                        <span className="ml-6 flex-shrink-0">
                          {isOpen ? (
                            <ChevronUp className="h-6 w-6 text-gray-500 dark:text-gray-400" />
                          ) : (
                            <ChevronDown className="h-6 w-6 text-gray-500 dark:text-gray-400" />
                          )}
                        </span>
                      </button>
                      {isOpen && (
                        <div className="mt-4 text-base text-gray-600 dark:text-gray-400">
                          {item.answer}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center bg-indigo-50 dark:bg-indigo-900/30 rounded-lg p-8">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Still have questions?
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Can't find the answer you're looking for? Our support team is here to help.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
          >
            Contact Support
          </Link>
        </div>
      </div>
    </div>
  );
} 