import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseConfig } from '../lib/firebase';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

export interface EmailData {
  name: string;
  email: string;
  subject: string;
  message: string;
  recaptchaToken: string;
}

export const sendContactEmail = async (data: EmailData) => {
  try {
    const sendEmail = httpsCallable(functions, 'sendContactEmail');
    const result = await sendEmail(data);
    return result.data;
  } catch (error: any) {
    console.error('Error sending email:', error);
    throw new Error(error.message);
  }
}; 