import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export function AdminRoute({ children }: { children: React.ReactNode }) {
  const { userProfile, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!userProfile || userProfile.userType !== 'admin') {
    // Redirect to appropriate dashboard based on user type
    if (userProfile?.userType === 'agent') {
      return <Navigate to="/agent/dashboard" />;
    } else if (userProfile?.userType === 'customer') {
      return <Navigate to="/dashboard" />;
    }
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}