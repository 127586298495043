import { ReactNode } from 'react';

export interface StatsCardProps {
  title: string;
  value: string | number;
  icon: ReactNode;
  color: 'green' | 'yellow' | 'blue' | 'orange';
}

export function StatsCard({ title, value, icon, color }: StatsCardProps) {
  const colorClasses = {
    green: 'text-green-600 dark:text-green-400',
    yellow: 'text-yellow-600 dark:text-yellow-400',
    blue: 'text-blue-600 dark:text-blue-400',
    orange: 'text-orange-600 dark:text-orange-400'
  };

  return (
    <div className="bg-white dark:bg-gray-800/50 rounded-lg p-4 shadow">
      <div className="flex items-center">
        <div className={`${colorClasses[color]} mr-3`}>
          {icon}
        </div>
        <div>
          <p className="text-sm text-gray-600 dark:text-gray-400">{title}</p>
          <p className={`text-2xl font-semibold ${colorClasses[color]}`}>
            {value}
          </p>
        </div>
      </div>
    </div>
  );
} 