import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../ui/Dialog';
import { Button } from '../ui/Button';

interface PaymentConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedCount: number;
  totalAmount: number;
  processing: boolean;
}

export function PaymentConfirmationDialog({
  isOpen,
  onClose,
  onConfirm,
  selectedCount,
  totalAmount,
  processing
}: PaymentConfirmationDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm Payment</DialogTitle>
          <DialogDescription>
            You are about to record payment for {selectedCount} application{selectedCount !== 1 ? 's' : ''}.
          </DialogDescription>
        </DialogHeader>
        
        <div className="py-4">
          <div className="flex justify-between items-center text-sm">
            <span>Applications:</span>
            <span className="font-medium">{selectedCount}</span>
          </div>
          <div className="flex justify-between items-center text-sm mt-2">
            <span>Rate per application:</span>
            <span className="font-medium">$3.00</span>
          </div>
          <div className="flex justify-between items-center text-lg font-medium mt-4 pt-4 border-t">
            <span>Total Amount:</span>
            <span className="text-green-600 dark:text-green-400">${totalAmount.toFixed(2)}</span>
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={processing}>
            Cancel
          </Button>
          <Button 
            onClick={onConfirm} 
            disabled={processing}
            className="bg-indigo-600 hover:bg-indigo-700"
          >
            {processing ? (
              <div className="flex items-center">
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2" />
                Processing...
              </div>
            ) : (
              'Confirm Payment'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 