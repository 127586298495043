import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { storage, db } from '../../lib/firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { sendEmailVerification, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import toast from 'react-hot-toast';
import { ProfileForm } from './ProfileForm';
import { PhotoUpload } from './PhotoUpload';
import { ResumeUpload } from './ResumeUpload';
import { PasswordChange } from './PasswordChange';
import { DeleteAccountModal } from '../modals/DeleteAccountModal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth } from '../../lib/firebase';

export function CustomerProfile() {
  const { user, userProfile, fetchUserProfile } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: ''
  });

  useEffect(() => {
    if (userProfile) {
      setFormData({
        firstName: userProfile.firstName,
        lastName: userProfile.lastName
      });
    }
  }, [userProfile]);

  const handleProfileUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setLoading(true);
      await updateDoc(doc(db, 'users', user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName
      });
      
      await fetchUserProfile(user.uid);
      setEditMode(false);
      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (file: File) => {
    if (!user) return;

    try {
      // Delete existing photo if any
      if (userProfile?.photoURL) {
        await handleDeletePhoto();
      }

      const fileExt = file.name.split('.').pop();
      const photoRef = ref(storage, `${user.uid}/photos/profile.${fileExt}`);
      await uploadBytes(photoRef, file);
      const photoURL = await getDownloadURL(photoRef);
      
      await updateDoc(doc(db, 'users', user.uid), {
        photoURL
      });

      await fetchUserProfile(user.uid);
      toast.success('Profile picture updated successfully');
    } catch (error) {
      toast.error('Failed to upload profile picture');
    }
  };

  const handleDeletePhoto = async () => {
    if (!user || !userProfile?.photoURL) return;

    try {
      const photoPath = userProfile.photoURL.split('?')[0].split('/o/')[1];
      if (!photoPath) {
        throw new Error('Invalid photo URL');
      }

      const decodedPath = decodeURIComponent(photoPath);
      const photoRef = ref(storage, decodedPath);

      await deleteObject(photoRef);
      await updateDoc(doc(db, 'users', user.uid), {
        photoURL: null
      });
      await fetchUserProfile(user.uid);
      
      toast.success('Profile picture deleted successfully');
    } catch (error: any) {
      if (error.code === 'storage/object-not-found') {
        try {
          await updateDoc(doc(db, 'users', user.uid), {
            photoURL: null
          });
          await fetchUserProfile(user.uid);
          toast.success('Profile picture removed successfully');
        } catch (dbError) {
          toast.error('Failed to update profile');
        }
      } else {
        toast.error('Failed to delete profile picture');
      }
    }
  };

  const handleResumeUpload = async (file: File) => {
    if (!user) return;

    try {
      // Delete existing resume if any
      if (userProfile?.resumeURL) {
        await handleDeleteResume();
      }

      const resumeRef = ref(storage, `${user.uid}/resume/profile/${file.name}`);
      await uploadBytes(resumeRef, file);
      const resumeURL = await getDownloadURL(resumeRef);
      
      await updateDoc(doc(db, 'users', user.uid), {
        resumeURL,
        resumeFileName: file.name
      });

      await fetchUserProfile(user.uid);
      toast.success('Resume uploaded successfully');
    } catch (error) {
      toast.error('Failed to upload resume');
    }
  };

  const handleDeleteResume = async () => {
    if (!user || !userProfile?.resumeURL) return;

    try {
      const resumePath = userProfile.resumeURL.split('?')[0].split('/o/')[1];
      if (!resumePath) {
        throw new Error('Invalid resume URL');
      }

      const decodedPath = decodeURIComponent(resumePath);
      const resumeRef = ref(storage, decodedPath);

      await deleteObject(resumeRef);
      await updateDoc(doc(db, 'users', user.uid), {
        resumeURL: null,
        resumeFileName: null
      });
      await fetchUserProfile(user.uid);
      
      toast.success('Resume deleted successfully');
    } catch (error: any) {
      if (error.code === 'storage/object-not-found') {
        try {
          await updateDoc(doc(db, 'users', user.uid), {
            resumeURL: null,
            resumeFileName: null
          });
          await fetchUserProfile(user.uid);
          toast.success('Resume removed successfully');
        } catch (dbError) {
          toast.error('Failed to update profile');
        }
      } else {
        toast.error('Failed to delete resume');
      }
    }
  };

  const handleVerifyEmail = async () => {
    if (!user) return;

    try {
      await sendEmailVerification(user);
      toast.success('Verification email sent. Please check your inbox.');
    } catch (error) {
      toast.error('Failed to send verification email');
    }
  };

  const handleDeleteAccount = async (password: string) => {
    if (!user) return;

    try {
      // Re-authenticate user
      const credential = EmailAuthProvider.credential(user.email!, password);
      await reauthenticateWithCredential(user, credential);

      // Call the deleteUser function
      const functions = getFunctions();
      const deleteUserAccount = httpsCallable(functions, 'deleteUserAccount');
      
      try {
        await deleteUserAccount({ uid: user.uid });
        toast.success('Account deleted successfully');
        
        // Sign out the user
        await auth.signOut();
        navigate('/', { replace: true });
      } catch (functionError: any) {
        console.error('Firebase Function Error:', {
          error: functionError,
          details: functionError.details,
          message: functionError.message,
          code: functionError.code,
          data: functionError.data
        });
        
        // Show the detailed error message from the function
        if (functionError.details) {
          toast.error(`Error: ${functionError.details}`);
        } else if (functionError.message) {
          toast.error(`Error: ${functionError.message}`);
        } else {
          toast.error(`Failed to delete account: ${JSON.stringify(functionError)}`);
        }
      }
    } catch (error: any) {
      console.error('Authentication Error:', {
        error: error,
        code: error.code,
        message: error.message
      });
      
      // Handle specific Firebase Auth errors
      if (error.code === 'auth/wrong-password') {
        toast.error('Incorrect password');
      } else if (error.code === 'auth/requires-recent-login') {
        toast.error('Please sign out and sign in again before deleting your account');
      } else if (error.code === 'auth/too-many-requests') {
        toast.error('Too many attempts. Please try again later');
      } else if (error.message) {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error(`Failed to delete account: ${JSON.stringify(error)}`);
      }
    }
  };

  if (!userProfile) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-3xl mx-auto space-y-6">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Profile Settings</h1>

        <PhotoUpload
          photoURL={userProfile?.photoURL || null}
          firstName={userProfile?.firstName || ''}
          lastName={userProfile?.lastName || ''}
          onUpload={handlePhotoUpload}
          onDelete={handleDeletePhoto}
        />

        <ProfileForm
          firstName={formData.firstName}
          lastName={formData.lastName}
          email={user?.email}
          isVerified={userProfile.isVerified}
          editMode={editMode}
          loading={loading}
          onEdit={() => setEditMode(true)}
          onCancel={() => {
            setFormData({
              firstName: userProfile.firstName,
              lastName: userProfile.lastName
            });
            setEditMode(false);
          }}
          onSubmit={handleProfileUpdate}
          onChange={(field, value) => setFormData(prev => ({ ...prev, [field]: value }))}
          onVerify={handleVerifyEmail}
          onDeleteClick={() => setShowDeleteModal(true)}
        />

        <ResumeUpload
          resumeURL={userProfile?.resumeURL || null}
          onUpload={handleResumeUpload}
          onDelete={handleDeleteResume}
        />

        <PasswordChange />

        {showDeleteModal && (
          <DeleteAccountModal
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteAccount}
            email={user?.email || ''}
          />
        )}
      </div>
    </div>
  );
}