import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { LogOut } from 'lucide-react';

interface SignOutButtonProps {
  variant?: 'icon' | 'full';
  className?: string;
}

export function SignOutButton({ variant = 'icon', className = '' }: SignOutButtonProps) {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  if (variant === 'full') {
    return (
      <button
        onClick={handleSignOut}
        className={`inline-flex items-center px-4 py-2 text-white hover:text-white hover:bg-gray-800 dark:hover:bg-gray-600 transition-colors duration-200 ${className}`}
      >
        <LogOut className="h-4 w-4 mr-2" />
        Sign Out
      </button>
    );
  }

  return (
    <button
      onClick={handleSignOut}
      className="text-white hover:text-white p-2 rounded-full hover:bg-gray-800 dark:hover:bg-gray-600 transition-colors duration-200"
    >
      <LogOut className="h-5 w-5" />
    </button>
  );
}