import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useCustomerStats } from '../../hooks/useCustomerStats';
import { FileText, Clock, AlertTriangle, CheckCircle, Activity, TrendingUp, GaugeCircle } from 'lucide-react';
import { GiTwoCoins } from 'react-icons/gi';
import { Link, Navigate } from 'react-router-dom';
import { EmailVerificationBanner } from '../EmailVerificationBanner';
import { StatsCard } from '../cards/StatsCard';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';

export function CustomerDashboard() {
  const { user, userProfile } = useAuth();
  const { stats, loading } = useCustomerStats();
  const [jobProfilesCount, setJobProfilesCount] = useState(0);

  useEffect(() => {
    if (user) {
      loadJobProfilesCount();
    }
  }, [user]);

  const loadJobProfilesCount = async () => {
    try {
      const q = query(
        collection(db, 'jobProfiles'),
        where('userId', '==', user?.uid)
      );
      const snapshot = await getDocs(q);
      setJobProfilesCount(snapshot.size);
    } catch (error) {
      console.error('Error loading job profiles count:', error);
      setJobProfilesCount(0);
    }
  };

  const formatTime = (ms: number) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    if (hours < 24) return `${hours} hours`;
    const days = Math.floor(hours / 24);
    return `${days} days`;
  };

  if (!userProfile) return null;

  // Redirect non-customer users to their appropriate dashboards
  if (userProfile.userType !== 'customer') {
    switch (userProfile.userType) {
      case 'admin':
        return <Navigate to="/admin/dashboard" />;
      case 'agent':
        return <Navigate to="/agent/dashboard" />;
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      {!user?.emailVerified && <EmailVerificationBanner />}
      
      <div className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              Welcome, {userProfile.firstName}!
            </h1>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Track your applications and manage your job search journey.
            </p>
          </div>
          <Link
            to="/submit-application"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
          >
            Submit Application
          </Link>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
        </div>
      ) : (
        <div className="space-y-8">
          {/* Primary Stats */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            <StatsCard
              title="Active Applications"
              value={stats.activeApplications.toString()}
              icon={Activity}
              variant="primary"
              linkTo="/applications"
            />
            <StatsCard
              title="Completed"
              value={stats.completedApplications.toString()}
              icon={CheckCircle}
              variant="success"
              linkTo="/applications"
            />
            <StatsCard
              title="Pending Info"
              value={stats.pendingApplications.toString()}
              icon={AlertTriangle}
              variant="warning"
              linkTo="/applications"
            />
            <StatsCard
              title="Available Credits"
              value={`${userProfile.credits || 0} CP`}
              icon={GiTwoCoins}
              iconClassName="text-yellow-400"
              linkTo="/credits"
            />
          </div>

          {/* Secondary Stats */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            <StatsCard
              title="Success Rate"
              value={`${Math.round(stats.successRate)}%`}
              icon={TrendingUp}
              variant="info"
            />
            <StatsCard
              title="Avg. Completion Time"
              value={formatTime(stats.averageCompletionTime)}
              icon={Clock}
              variant="warning"
            />
            <StatsCard
              title="Job Profiles"
              value={jobProfilesCount.toString()}
              icon={FileText}
              linkTo="/job-profiles"
            />
            <StatsCard
              title="Total Applications"
              value={stats.totalApplications.toString()}
              icon={GaugeCircle}
              variant="primary"
            />
          </div>

          {/* Activity Summary */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Recent Activity</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">Today</p>
                <p className="mt-1 text-2xl font-semibold text-gray-900 dark:text-white">
                  {stats.recentActivity.today}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">applications submitted</p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">This Week</p>
                <p className="mt-1 text-2xl font-semibold text-gray-900 dark:text-white">
                  {stats.recentActivity.week}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">applications submitted</p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">This Month</p>
                <p className="mt-1 text-2xl font-semibold text-gray-900 dark:text-white">
                  {stats.recentActivity.month}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">applications submitted</p>
              </div>
            </div>
          </div>

          {/* Credit Usage */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Credit Usage</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Credits Spent</span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">{stats.creditsSpent} CP</span>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                  <div 
                    className="bg-yellow-400 dark:bg-yellow-500 h-2.5 rounded-full" 
                    style={{ width: `${Math.min(100, (stats.creditsSpent / 100) * 100)}%` }}
                  ></div>
                </div>
                <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                  {stats.creditsSpent} credits used for {stats.totalApplications} applications
                </p>
              </div>

              <div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Available Credits</span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">{userProfile.credits || 0} CP</span>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                  <div 
                    className="bg-green-500 dark:bg-green-400 h-2.5 rounded-full" 
                    style={{ 
                      width: `${Math.min(100, ((userProfile.credits || 0) / 50) * 100)}%` 
                    }}
                  ></div>
                </div>
                <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                  Enough for {Math.floor((userProfile.credits || 0) / 3)} more applications
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}