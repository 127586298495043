import { useState } from 'react';
import { Application } from '../../types/application';
import { Checkbox, Button } from '../../components/ui';

interface AgentPaymentCardProps {
  agentId: string;
  pendingApplications: Application[];
  paidApplications: Application[];
  onRecordPayment: (agentId: string, applicationIds: string[]) => Promise<void>;
}

export function AgentPaymentCard({
  agentId,
  pendingApplications,
  paidApplications,
  onRecordPayment
}: AgentPaymentCardProps) {
  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    if (selectedApps.length === 0) return;
    
    setLoading(true);
    try {
      await onRecordPayment(agentId, selectedApps);
      setSelectedApps([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
      <div className="space-y-4">
        <h3 className="text-lg font-medium">Pending Applications</h3>
        {pendingApplications.map(app => (
          <div key={app.id} className="flex items-center space-x-3">
            <Checkbox
              checked={selectedApps.includes(app.id)}
              onChange={(checked: boolean) => {
                setSelectedApps(prev => 
                  checked 
                    ? [...prev, app.id]
                    : prev.filter(id => id !== app.id)
                );
              }}
            />
            <span>{app.company} - {app.position}</span>
            <span className="text-sm text-gray-500">
              Completed: {app.completedAt ? new Date(app.completedAt).toLocaleDateString() : 'N/A'}
            </span>
          </div>
        ))}

        {pendingApplications.length > 0 && (
          <Button
            onClick={handlePayment}
            disabled={selectedApps.length === 0 || loading}
            className="mt-4"
          >
            Record Payment for Selected
          </Button>
        )}

        <h3 className="text-lg font-medium mt-6">Paid Applications</h3>
        {paidApplications.map(app => (
          <div key={app.id} className="flex items-center space-x-3 text-gray-500">
            <span>{app.company} - {app.position}</span>
            <span className="text-sm">
              Paid: {app.paymentDate ? new Date(app.paymentDate).toLocaleDateString() : 'N/A'}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
} 