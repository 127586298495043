import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { db } from '../lib/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { Upload, Trash2 } from 'lucide-react';
import { FaRegFilePdf } from 'react-icons/fa6';
import { uploadFile, deleteFile } from '../utils/storage';
import toast from 'react-hot-toast';

interface FormData {
  profileName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
}

interface JobProfile extends FormData {
  id: string;
  userId: string;
  resumeURL?: string;
  resumeFileName?: string;
  createdAt: string;
  updatedAt: string;
}

export function JobProfileForm() {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    profileName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: ''
  });
  const [resumeURL, setResumeURL] = useState<string>();
  const [resumeFileName, setResumeFileName] = useState<string>();

  useEffect(() => {
    if (id) {
      loadProfile();
    }
  }, [id, user]);

  async function loadProfile() {
    if (!id || !user) return;

    try {
      const docRef = doc(db, 'jobProfiles', id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data() as JobProfile;
        setFormData({
          profileName: data.profileName || '',
          firstName: data.firstName || '',
          middleName: data.middleName || '',
          lastName: data.lastName || '',
          phoneNumber: data.phoneNumber || ''
        });
        if (data.resumeURL) setResumeURL(data.resumeURL);
        if (data.resumeFileName) setResumeFileName(data.resumeFileName);
      }
    } catch (error) {
      console.error('Error loading profile:', error);
      toast.error('Failed to load profile');
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setLoading(true);
      const profileId = id || uuidv4();
      const profileData = {
        ...formData,
        userId: user.uid,
        resumeURL,
        resumeFileName,
        updatedAt: new Date().toISOString(),
        ...(id ? {} : { createdAt: new Date().toISOString() })
      };

      const userRef = doc(db, 'users', user.uid);
      const profileRef = doc(db, 'jobProfiles', profileId);

      if (id) {
        await updateDoc(profileRef, profileData);
      } else {
        await setDoc(profileRef, profileData);
        await updateDoc(userRef, {
          [`userJobProfiles.${profileId}`]: {
            profileName: formData.profileName,
            createdAt: new Date().toISOString()
          }
        });
      }

      toast.success(id ? 'Profile updated successfully' : 'Profile created successfully');
      navigate('/job-profiles');
    } catch (error) {
      console.error('Error:', error);
      toast.error(id ? 'Failed to update profile' : 'Failed to create profile');
    } finally {
      setLoading(false);
    }
  };

  const handleResumeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0] || !user) return;
    
    const file = e.target.files[0];
    if (file.type !== 'application/pdf') {
      toast.error('Please upload a PDF file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error('File size must be less than 5MB');
      return;
    }

    try {
      setUploading(true);
      const profileId = id || uuidv4();
      const result = await uploadFile(user.uid, file, `resume/profiles/${profileId}`);
      
      setResumeURL(result.url);
      setResumeFileName(result.fileName);

      if (id) {
        await updateDoc(doc(db, 'jobProfiles', id), {
          resumeURL: result.url,
          resumeFileName: result.fileName
        });
      }

      toast.success('Resume uploaded successfully');
    } catch (error: any) {
      console.error('Error uploading resume:', error);
      toast.error(error.message || 'Failed to upload resume');
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDeleteResume = async () => {
    if (!resumeURL || !user) return;

    try {
      const resumePath = resumeURL.split('?')[0].split('/o/')[1];
      if (!resumePath) {
        throw new Error('Invalid resume URL');
      }

      await deleteFile(decodeURIComponent(resumePath));
      setResumeURL(undefined);
      setResumeFileName(undefined);

      if (id) {
        await updateDoc(doc(db, 'jobProfiles', id), {
          resumeURL: null,
          resumeFileName: null
        });
      }

      toast.success('Resume deleted successfully');
    } catch (error: any) {
      console.error('Error deleting resume:', error);
      toast.error(error.message || 'Failed to delete resume');
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          {id ? 'Edit Job Profile' : 'New Job Profile'}
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <div className="space-y-6">
              <div>
                <label htmlFor="profileName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Profile Name
                </label>
                <input
                  type="text"
                  id="profileName"
                  required
                  className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.profileName}
                  onChange={(e) => setFormData({ ...formData, profileName: e.target.value })}
                />
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    required
                    className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.firstName}
                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                  />
                </div>
                <div>
                  <label htmlFor="middleName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Middle Name
                  </label>
                  <input
                    type="text"
                    id="middleName"
                    className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.middleName}
                    onChange={(e) => setFormData({ ...formData, middleName: e.target.value })}
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    required
                    className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.lastName}
                    onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  required
                  className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Resume
                </label>
                <div className="mt-1 flex items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => fileInputRef.current?.click()}
                    disabled={uploading}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
                  >
                    <Upload className="h-4 w-4 mr-2" />
                    Upload Resume (PDF)
                  </button>
                  {resumeURL && (
                    <button
                      type="button"
                      onClick={handleDeleteResume}
                      disabled={uploading}
                      className="inline-flex items-center px-4 py-2 border border-red-300 dark:border-red-500 rounded-md shadow-sm text-sm font-medium text-red-700 dark:text-red-400 bg-white dark:bg-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 disabled:opacity-50"
                    >
                      <Trash2 className="h-4 w-4 mr-2" />
                      Delete
                    </button>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="application/pdf"
                  onChange={handleResumeUpload}
                />
                {uploading && (
                  <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    Uploading resume...
                  </div>
                )}
                {resumeURL && resumeFileName && !uploading && (
                  <a
                    href={resumeURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2 flex items-center space-x-2 p-3 bg-gray-50 dark:bg-gray-700 rounded-md hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-200 group"
                  >
                    <FaRegFilePdf className="h-6 w-6 text-red-500 dark:text-red-400 group-hover:text-red-600 dark:group-hover:text-red-300" />
                    <span className="text-sm font-medium text-gray-900 dark:text-white group-hover:text-indigo-600 dark:group-hover:text-indigo-400">
                      {resumeFileName}
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end space-x-3">
            <button
              type="button"
              onClick={() => navigate('/job-profiles')}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {loading ? 'Saving...' : id ? 'Update Profile' : 'Create Profile'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}