import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { UserProfile } from '../types/user';
import { Application } from '../types/application';
import { StatsCard } from '../components/ui';
import { ProfilePicture } from '../components/ui/ProfilePicture';
import { ApplicationsTable } from '../components/tables/ApplicationsTable';
import { PaymentsTable } from '../components/tables/PaymentsTable';
import { Clock, CheckCircle2, DollarSign, AlertCircle, ArrowLeft, Mail, Phone } from 'lucide-react';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';

interface AgentStats {
  totalCompleted: number;
  totalPaid: number;
  totalUnpaid: number;
  totalPending: number;
  avgCompletionTime: number;
}

export function AgentDetails() {
  const { agentId } = useParams<{ agentId: string }>();
  const navigate = useNavigate();
  const [agent, setAgent] = useState<UserProfile | null>(null);
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<AgentStats>({
    totalCompleted: 0,
    totalPaid: 0,
    totalUnpaid: 0,
    totalPending: 0,
    avgCompletionTime: 0
  });

  useEffect(() => {
    const loadAgentDetails = async () => {
      if (!agentId) {
        navigate('/admin/agents');
        return;
      }

      try {
        // Load agent profile
        const agentDoc = await getDoc(doc(db, 'users', agentId));
        if (!agentDoc.exists()) {
          throw new Error('Agent not found');
        }
        setAgent({ id: agentId, ...agentDoc.data() } as UserProfile);

        // Load applications
        const applicationsQuery = query(
          collection(db, 'applications'),
          where('agentId', '==', agentId)
        );
        const snapshot = await getDocs(applicationsQuery);
        const apps = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Application[];
        setApplications(apps);

        // Calculate stats
        const completed = apps.filter(app => app.status === 'completed');
        const paid = completed.filter(app => app.paymentStatus === 'paid');
        const pending = apps.filter(app => app.status === 'pending');
        
        const completionTimes = completed
          .map(app => app.fulfillmentTime || 0)
          .filter(time => time > 0);
        const avgTime = completionTimes.length > 0
          ? completionTimes.reduce((a, b) => a + b, 0) / completionTimes.length / (1000 * 60 * 60 * 24)
          : 0;

        setStats({
          totalCompleted: completed.length,
          totalPaid: paid.length,
          totalUnpaid: completed.length - paid.length,
          totalPending: pending.length,
          avgCompletionTime: avgTime
        });
      } catch (error) {
        console.error('Error loading agent details:', error);
      } finally {
        setLoading(false);
      }
    };

    loadAgentDetails();
  }, [agentId, navigate]);

  if (loading) {
    return <LoadingSpinner size="lg" />;
  }

  if (!agentId || !agent) {
    return <LoadingSpinner size="lg" />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Link
        to="/admin/dashboard"
        className="flex items-center text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white mb-6"
      >
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Dashboard
      </Link>

      {/* Agent Profile Section */}
      <div className="mb-8">
        <div className="flex items-center">
          <ProfilePicture
            photoURL={agent?.photoURL || null}
            firstName={agent?.firstName || ''}
            lastName={agent?.lastName || ''}
            size="lg"
            className="mr-6"
          />
          <div>
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              {agent?.firstName} {agent?.lastName}
            </h1>
            <div className="mt-1 space-y-1">
              <p className="text-gray-500 dark:text-gray-400 flex items-center">
                <Mail className="h-4 w-4 mr-2" />
                {agent?.email}
              </p>
              <p className="text-gray-500 dark:text-gray-400 flex items-center">
                <Phone className="h-4 w-4 mr-2" />
                {agent?.phoneNumber || 'No phone number'}
              </p>
              <div className="flex items-center space-x-2">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  agent?.isVerified 
                    ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                    : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                }`}>
                  {agent?.isVerified ? 'Verified' : 'Pending'}
                </span>
                {agent?.isKaput && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                    Decommissioned
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
        <StatsCard
          title="Total Completed"
          value={stats.totalCompleted}
          icon={<CheckCircle2 className="h-5 w-5" />}
          color="green"
        />
        <StatsCard
          title="Pending Payment"
          value={stats.totalUnpaid}
          icon={<DollarSign className="h-5 w-5" />}
          color="yellow"
        />
        <StatsCard
          title="Average Completion Time"
          value={`${Math.round(stats.avgCompletionTime)} days`}
          icon={<Clock className="h-5 w-5" />}
          color="blue"
        />
        <StatsCard
          title="Pending Applications"
          value={stats.totalPending}
          icon={<AlertCircle className="h-5 w-5" />}
          color="orange"
        />
      </div>

      {/* Applications Section */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Agent Applications</h2>
          <Link 
            to={`/admin/agents/${agentId}/applications`}
            className="text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
          >
            View All
          </Link>
        </div>
        <ApplicationsTable 
          applications={applications.slice(0, 5)}
          showPaymentStatus
        />
      </div>

      {/* Payments Section */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Payment History</h2>
          <Link 
            to={`/admin/agents/${agentId}/make-payment`}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-400"
          >
            Make Payment
          </Link>
        </div>
        <PaymentsTable agentId={agentId} />
      </div>
    </div>
  );
} 