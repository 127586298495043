import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useApplicationActions } from '../hooks/useApplicationActions';
import { db } from '../lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Application } from '../types/application';
import { FileText } from 'lucide-react';
import toast from 'react-hot-toast';

interface JobProfile {
  id: string;
  profileName: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  resumeURL?: string;
}

export function SubmitApplication() {
  const { user, userProfile } = useAuth();
  const { submitApplication } = useApplicationActions();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [profiles, setProfiles] = useState<JobProfile[]>([]);
  const [selectedProfileId, setSelectedProfileId] = useState('');
  const [formData, setFormData] = useState({
    jobUrl: '',
    company: '',
    position: '',
    notes: ''
  });

  useEffect(() => {
    if (user) {
      loadProfiles();
    }
  }, [user]);

  async function loadProfiles() {
    if (!user) return;

    try {
      const q = query(
        collection(db, 'jobProfiles'),
        where('userId', '==', user.uid)
      );
      const querySnapshot = await getDocs(q);
      const profilesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as JobProfile[];
      
      setProfiles(profilesList);
      if (profilesList.length === 1) {
        setSelectedProfileId(profilesList[0].id);
      }
    } catch (error) {
      console.error('Error loading profiles:', error);
      toast.error('Failed to load profiles');
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProfileId || !user || !userProfile) {
      toast.error('Please select a job profile');
      return;
    }

    // Check if user has enough credits
    if ((userProfile.credits || 0) < 3) {
      toast.error('Insufficient credits. You need 3 credits to submit an application.');
      return;
    }

    try {
      setSubmitting(true);

      // Get selected profile data
      const selectedProfile = profiles.find(p => p.id === selectedProfileId);
      if (!selectedProfile) throw new Error('Profile not found');

      // Create application data
      const applicationData: Omit<Application, 'id' | 'createdAt' | 'updatedAt'> = {
        userId: user.uid,
        profileId: selectedProfileId,
        profileName: selectedProfile.profileName,
        jobUrl: formData.jobUrl,
        company: formData.company,
        position: formData.position,
        notes: formData.notes,
        status: 'submitted',
        priority: 0,
        lastActionBy: user.uid,
        lastActionAt: new Date().toISOString(),
        comments: []
      };

      await submitApplication(applicationData);
      navigate('/applications');
    } catch (error: any) {
      console.error('Error submitting application:', error);
      toast.error(error.message || 'Failed to submit application. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
      </div>
    );
  }

  if (profiles.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
        <div className="text-center py-12 bg-white dark:bg-gray-800 shadow rounded-lg">
          <FileText className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" />
          <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">No job profiles found</h3>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Please create a job profile before submitting applications.
          </p>
          <div className="mt-6">
            <a
              href="/job-profiles/new"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              Create Job Profile
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Submit New Application</h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <div className="space-y-6">
              <div>
                <label htmlFor="profile" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Select Job Profile
                </label>
                <select
                  id="profile"
                  required
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={selectedProfileId}
                  onChange={(e) => setSelectedProfileId(e.target.value)}
                >
                  <option value="">Choose a profile</option>
                  {profiles.map((profile) => (
                    <option key={profile.id} value={profile.id}>
                      {profile.profileName}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="jobUrl" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Job URL
                </label>
                <input
                  type="url"
                  id="jobUrl"
                  required
                  className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.jobUrl}
                  onChange={(e) => setFormData({ ...formData, jobUrl: e.target.value })}
                />
              </div>

              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Company Name
                </label>
                <input
                  type="text"
                  id="company"
                  required
                  className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.company}
                  onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                />
              </div>

              <div>
                <label htmlFor="position" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Position Title
                </label>
                <input
                  type="text"
                  id="position"
                  required
                  className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.position}
                  onChange={(e) => setFormData({ ...formData, position: e.target.value })}
                />
              </div>

              <div>
                <label htmlFor="notes" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Additional Notes
                </label>
                <textarea
                  id="notes"
                  rows={4}
                  className="mt-1 px-4 py-3 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.notes}
                  onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                  placeholder="Add any specific requirements or notes for the agent handling your application..."
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              This will use 3 credits from your balance (Current balance: {userProfile?.credits || 0} CP)
            </p>
            <div className="flex space-x-3">
              <button
                type="button"
                onClick={() => navigate('/applications')}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={submitting || !selectedProfileId || (userProfile?.credits || 0) < 3}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              >
                {submitting ? 'Submitting...' : 'Submit Application'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}