import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useAuth } from './hooks/useAuth';
import { PrivateRoute } from './components/PrivateRoute';
import { AdminRoute } from './components/AdminRoute';
import { AgentRoute } from './components/AgentRoute';
import { Navbar } from './components/Navbar';
import { Footer } from './components/layout/Footer';
import { OfflineBanner } from './components/OfflineBanner';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { CustomerDashboard } from './components/dashboards/CustomerDashboard';
import { Profile } from './pages/Profile';
import { Applications } from './pages/Applications';
import { JobProfiles } from './pages/JobProfiles';
import { CreateJobProfile } from './pages/CreateJobProfile';
import { JobProfileForm } from './pages/JobProfileForm';
import { SubmitApplication } from './pages/SubmitApplication';
import { ApplicationDetailsPage } from './pages/ApplicationDetailsPage';
import { Credits } from './pages/Credits';
import { AgentApplications } from './pages/AgentApplications';
import { AgentDashboard } from './components/dashboards/AgentDashboard';
import { AdminDashboard } from './pages/AdminDashboard';
import { AdminApplications } from './pages/AdminApplications';
import { AdminAgents } from './pages/AdminAgents';
import { AdminCustomers } from './pages/AdminCustomers';
import { Blog } from './pages/Blog';
import { Pricing } from './pages/Pricing';
import { Careers } from './pages/Careers';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Privacy } from './pages/Privacy';
import { Terms } from './pages/Terms';
import { Cookies } from './pages/Cookies';
import { GDPR } from './pages/GDPR';
import { FAQ } from './pages/FAQ';
import { AdminPayments } from './components/dashboards/AdminPayments';
import { AgentDetails } from './pages/AgentDetails';
import { MakePayment } from './pages/MakePayment';

// Component to handle footer visibility
function AppContent() {
  const { user, userProfile } = useAuth();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const location = useLocation();

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // List of public routes where footer should be shown
  const publicRoutes = ['/', '/blog', '/pricing', '/careers', '/about', '/contact', '/login', '/register', '/privacy', '/terms', '/cookies', '/gdpr', '/faq'];
  const shouldShowFooter = !user && publicRoutes.includes(location.pathname);

  // Redirect to appropriate dashboard based on user type
  const getDashboardRedirect = () => {
    if (!user || !userProfile) return '/';
    switch (userProfile.userType) {
      case 'admin':
        return '/admin/dashboard';
      case 'agent':
        return '/agent/dashboard';
      default:
        return '/dashboard';
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <Navbar />
      {isOffline && <OfflineBanner />}
      <div className="flex-grow">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={user ? <Navigate to={getDashboardRedirect()} /> : <Home />} />
          <Route path="/login" element={user ? <Navigate to={getDashboardRedirect()} /> : <Login />} />
          <Route path="/register" element={user ? <Navigate to={getDashboardRedirect()} /> : <Register />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<Blog />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/gdpr" element={<GDPR />} />
          <Route path="/faq" element={<FAQ />} />

          {/* Customer Routes */}
          <Route path="/dashboard" element={
            <PrivateRoute>
              <CustomerDashboard />
            </PrivateRoute>
          } />

          <Route path="/profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />

          <Route path="/applications" element={
            <PrivateRoute>
              <Applications />
            </PrivateRoute>
          } />

          <Route path="/applications/:id" element={
            <PrivateRoute>
              <ApplicationDetailsPage />
            </PrivateRoute>
          } />

          <Route path="/job-profiles" element={
            <PrivateRoute>
              <JobProfiles />
            </PrivateRoute>
          } />

          <Route path="/job-profiles/new" element={
            <PrivateRoute>
              <CreateJobProfile />
            </PrivateRoute>
          } />

          <Route path="/job-profiles/:id/edit" element={
            <PrivateRoute>
              <JobProfileForm />
            </PrivateRoute>
          } />

          <Route path="/submit-application" element={
            <PrivateRoute>
              <SubmitApplication />
            </PrivateRoute>
          } />

          <Route path="/credits" element={
            <PrivateRoute>
              <Credits />
            </PrivateRoute>
          } />

          {/* Agent Routes */}
          <Route path="/agent/dashboard" element={
            <AgentRoute>
              <AgentDashboard />
            </AgentRoute>
          } />

          <Route path="/agent/applications" element={
            <AgentRoute>
              <AgentApplications />
            </AgentRoute>
          } />

          {/* Admin Routes */}
          <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />
          <Route path="/admin/dashboard" element={
            <AdminRoute>
              <AdminDashboard />
            </AdminRoute>
          } />

          <Route path="/admin/applications" element={
            <AdminRoute>
              <AdminApplications />
            </AdminRoute>
          } />

          <Route path="/admin/agents" element={
            <AdminRoute>
              <AdminAgents />
            </AdminRoute>
          } />

          <Route path="/admin/customers" element={
            <AdminRoute>
              <AdminCustomers />
            </AdminRoute>
          } />

          <Route path="/admin/payments" element={
            <AdminRoute>
              <AdminPayments />
            </AdminRoute>
          } />

          <Route path="/admin/agents/:agentId" element={
            <AdminRoute>
              <AgentDetails />
            </AdminRoute>
          } />
          
          <Route path="/admin/agents/:agentId/make-payment" element={
            <AdminRoute>
              <MakePayment />
            </AdminRoute>
          } />

          <Route path="/admin/agents/:agentId/applications" element={
            <AdminRoute>
              <AgentApplications />
            </AdminRoute>
          } />
        </Routes>
      </div>
      {shouldShowFooter && <Footer />}
    </div>
  );
}

export function App() {
  return (
    <Router>
      <AppContent />
      <Toaster 
        position="bottom-right"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 3000,
            iconTheme: {
              primary: '#4ade80',
              secondary: '#fff',
            },
          },
          error: {
            duration: 4000,
            iconTheme: {
              primary: '#ef4444',
              secondary: '#fff',
            },
          },
          id: 'unique',
        }}
      />
    </Router>
  );
}

export default App;