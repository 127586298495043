import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { db } from '../lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Application } from '../types/application';

interface CustomerStats {
  totalApplications: number;
  activeApplications: number;
  completedApplications: number;
  pendingApplications: number;
  averageCompletionTime: number;
  successRate: number;
  creditsSpent: number;
  recentActivity: {
    today: number;
    week: number;
    month: number;
  };
}

export function useCustomerStats() {
  const { user } = useAuth();
  const [stats, setStats] = useState<CustomerStats>({
    totalApplications: 0,
    activeApplications: 0,
    completedApplications: 0,
    pendingApplications: 0,
    averageCompletionTime: 0,
    successRate: 0,
    creditsSpent: 0,
    recentActivity: {
      today: 0,
      week: 0,
      month: 0
    }
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      loadStats();
    }
  }, [user]);

  const loadStats = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const q = query(
        collection(db, 'applications'),
        where('userId', '==', user.uid)
      );
      const snapshot = await getDocs(q);
      const applications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Application[];

      // Calculate basic stats
      const completed = applications.filter(app => app.status === 'completed');
      const pending = applications.filter(app => app.status === 'pending');
      const active = applications.filter(app => 
        ['submitted', 'in_progress', 'resubmitted'].includes(app.status)
      );
      
      // Calculate completion times for completed applications
      const completionTimes = completed
        .filter(app => app.fulfillmentTime)
        .map(app => app.fulfillmentTime as number);
      
      const avgCompletionTime = completionTimes.length > 0
        ? completionTimes.reduce((acc, time) => acc + time, 0) / completionTimes.length
        : 0;

      // Calculate success rate (completed vs cancelled)
      const cancelled = applications.filter(app => app.status === 'cancelled');
      const successRate = applications.length > 0
        ? (completed.length / (completed.length + cancelled.length)) * 100
        : 0;

      // Calculate credits spent (3 credits per application)
      const creditsSpent = applications.length * 3;

      // Calculate recent activity
      const now = new Date();
      const today = new Date(now.setHours(0, 0, 0, 0));
      const weekAgo = new Date(today);
      weekAgo.setDate(weekAgo.getDate() - 7);
      const monthAgo = new Date(today);
      monthAgo.setMonth(monthAgo.getMonth() - 1);

      const recentActivity = {
        today: applications.filter(app => new Date(app.createdAt) >= today).length,
        week: applications.filter(app => new Date(app.createdAt) >= weekAgo).length,
        month: applications.filter(app => new Date(app.createdAt) >= monthAgo).length
      };

      setStats({
        totalApplications: applications.length,
        activeApplications: active.length,
        completedApplications: completed.length,
        pendingApplications: pending.length,
        averageCompletionTime: avgCompletionTime,
        successRate,
        creditsSpent,
        recentActivity
      });
    } catch (error) {
      console.error('Error loading customer stats:', error);
    } finally {
      setLoading(false);
    }
  };

  return { stats, loading, refresh: loadStats };
}