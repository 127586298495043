import { useMemo, useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Application } from '../../types/application';
import { writeBatch, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { AgentPaymentCard } from '../../components/payments/AgentPaymentCard';

export function AdminPayments() {
  const { user } = useAuth();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadApplications = async () => {
      if (!user) return;

      try {
        const q = query(
          collection(db, 'applications'),
          where('status', '==', 'completed')
        );
        const snapshot = await getDocs(q);
        const apps = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Application[];
        setApplications(apps);
      } catch (error) {
        console.error('Error loading applications:', error);
        toast.error('Failed to load applications');
      } finally {
        setLoading(false);
      }
    };

    loadApplications();
  }, [user]);

  // Group completed applications by agent
  const applicationsByAgent = useMemo(() => {
    return applications.reduce((acc, app) => {
      if (app.status === 'completed' && app.agentId) {
        if (!acc[app.agentId]) {
          acc[app.agentId] = {
            pending: [] as Application[],
            paid: [] as Application[]
          };
        }
        if (app.paymentStatus === 'paid') {
          acc[app.agentId].paid.push(app);
        } else {
          acc[app.agentId].pending.push(app);
        }
      }
      return acc;
    }, {} as Record<string, { pending: Application[], paid: Application[] }>);
  }, [applications]);

  const handleRecordPayment = async (agentId: string, selectedApplications: string[]) => {
    if (!user) return;

    try {
      const batch = writeBatch(db);
      
      // Create payment record
      const paymentRef = doc(collection(db, 'payments'));
      batch.set(paymentRef, {
        agentId,
        applications: selectedApplications,
        amount: selectedApplications.length * 3, // $3 per application
        date: new Date().toISOString(),
        createdBy: user.uid,
        createdAt: new Date().toISOString()
      });

      // Update application payment status
      selectedApplications.forEach(appId => {
        batch.update(doc(db, 'applications', appId), {
          paymentStatus: 'paid',
          paymentDate: new Date().toISOString()
        });
      });

      await batch.commit();
      toast.success('Payment recorded successfully');
    } catch (error) {
      console.error('Error recording payment:', error);
      toast.error('Failed to record payment');
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Agent Payments</h2>
      
      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
        </div>
      ) : (
        Object.entries(applicationsByAgent).map(([agentId, apps]) => (
          <AgentPaymentCard
            key={agentId}
            agentId={agentId}
            pendingApplications={apps.pending}
            paidApplications={apps.paid}
            onRecordPayment={handleRecordPayment}
          />
        ))
      )}
    </div>
  );
} 