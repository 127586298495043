import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

interface LogoProps {
  onClick?: (e: React.MouseEvent) => void;
}

export function Logo({ }: LogoProps) {
  const { user, userProfile } = useAuth();

  const getLogoLink = () => {
    if (!user || !userProfile) return '/';
    
    switch (userProfile.userType) {
      case 'admin':
        return '/admin/dashboard';
      case 'agent':
        return '/agent/dashboard';
      default:
        return '/dashboard';
    }
  };

  return (
    <Link 
      to={getLogoLink()}
      className="flex items-center px-1 py-1 rounded-md transition-all duration-200 hover:bg-gray-700/50 dark:hover:bg-gray-600/50"
    >
      <img 
        src="/logo.png"
        alt="BlitzApply" 
        className="h-10"
      />
    </Link>
  );
}