import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../lib/firebase';

export async function uploadFile(userId: string, file: File, path: string) {
  if (file.size > 5 * 1024 * 1024) {
    throw new Error('File size must be less than 5MB');
  }

  const allowedTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/gif',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  if (!allowedTypes.includes(file.type)) {
    throw new Error('Invalid file type. Only PDF, images, text, and Word documents are allowed.');
  }

  try {
    const encodedPath = encodeURIComponent(`${userId}/${path}/${file.name}`).replace(/%2F/g, '/');
    const fileRef = ref(storage, encodedPath);
    
    const metadata = {
      contentType: file.type,
      customMetadata: {
        'userId': userId,
        'originalName': file.name
      }
    };
    
    const snapshot = await uploadBytes(fileRef, file, metadata);
    const downloadURL = await getDownloadURL(snapshot.ref);
    
    return {
      url: downloadURL,
      path: encodedPath,
      fileName: file.name
    };
  } catch (error: any) {
    if (error.code === 'storage/unauthorized') {
      throw new Error('You do not have permission to upload files');
    }
    throw new Error('Failed to upload file. Please try again.');
  }
}

export async function deleteFile(path: string) {
  try {
    const fileRef = ref(storage, path);
    await deleteObject(fileRef);
  } catch (error: any) {
    if (error.code === 'storage/object-not-found') {
      return; // File already deleted or doesn't exist
    }
    throw new Error('Failed to delete file. Please try again.');
  }
}