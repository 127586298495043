import { useAuth } from '../../hooks/useAuth';
import { useAgentStats } from '../../hooks/useAgentStats';
import { AlertTriangle, CheckCircle, Clock, Activity, TrendingUp, Calendar } from 'lucide-react';
import { StatsCard } from '../cards/StatsCard';
import { EmailVerificationBanner } from '../EmailVerificationBanner';

export function AgentDashboard() {
  const { userProfile, user } = useAuth();
  const { stats, loading } = useAgentStats();

  if (!userProfile) return null;

  const formatTime = (ms: number) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    if (hours < 24) return `${hours} hours`;
    const days = Math.floor(hours / 24);
    return `${days} days`;
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      {!user?.emailVerified && <EmailVerificationBanner />}
      
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
          Welcome, {userProfile.firstName}!
        </h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Monitor your performance and manage applications efficiently.
        </p>
      </div>

      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
        </div>
      ) : (
        <div className="space-y-8">
          {/* Key Metrics */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            <StatsCard
              title="Completed Applications"
              value={stats.completedApplications.toString()}
              icon={CheckCircle}
              variant="success"
              linkTo="/agent/applications"
            />
            <StatsCard
              title="In Progress"
              value={stats.inProgressApplications.toString()}
              icon={Clock}
              variant="warning"
              linkTo="/agent/applications"
            />
            <StatsCard
              title="Pending Info"
              value={stats.pendingApplications.toString()}
              icon={AlertTriangle}
              variant="danger"
              linkTo="/agent/applications"
            />
            <StatsCard
              title="Total Applications"
              value={stats.totalApplications.toString()}
              icon={Activity}
              variant="primary"
              linkTo="/agent/applications"
            />
          </div>

          {/* Performance Metrics */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            <StatsCard
              title="Completion Rate"
              value={`${Math.round(stats.completionRate)}%`}
              icon={TrendingUp}
              variant="info"
            />
            <StatsCard
              title="Avg. Fulfillment Time"
              value={formatTime(stats.averageFulfillmentTime)}
              icon={Clock}
              variant="warning"
            />
            <StatsCard
              title="Today's Completions"
              value={stats.todayCompletions.toString()}
              icon={Calendar}
              variant="success"
            />
            <StatsCard
              title="Weekly Completions"
              value={stats.weeklyCompletions.toString()}
              icon={Calendar}
              variant="primary"
            />
          </div>

          {/* Performance Summary */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Performance Summary</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Completion Rate Progress */}
              <div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Completion Rate</span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">{Math.round(stats.completionRate)}%</span>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                  <div 
                    className="bg-green-600 h-2.5 rounded-full" 
                    style={{ width: `${Math.min(100, stats.completionRate)}%` }}
                  ></div>
                </div>
              </div>

              {/* Workload Distribution */}
              <div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Current Workload</span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    {stats.inProgressApplications} in progress
                  </span>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                  <div 
                    className="bg-yellow-500 h-2.5 rounded-full" 
                    style={{ 
                      width: `${Math.min(100, (stats.inProgressApplications / 5) * 100)}%` 
                    }}
                  ></div>
                </div>
                <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                  Recommended: 5 applications at a time
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}