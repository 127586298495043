import { Mail } from 'lucide-react';

export function Credits() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Credits (CP)</h1>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-8 mb-8">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Pricing Plans</h2>
          
          <div className="space-y-6">
            <div className="border border-indigo-100 dark:border-indigo-900 rounded-lg p-6 bg-indigo-50 dark:bg-indigo-900/30">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-indigo-900 dark:text-indigo-300">Starter Pack</h3>
                <span className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">$29</span>
              </div>
              <ul className="space-y-2 mb-4">
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  10 Credits
                </li>
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  3-4 Applications
                </li>
              </ul>
            </div>

            <div className="border border-indigo-100 dark:border-indigo-900 rounded-lg p-6 bg-indigo-50 dark:bg-indigo-900/30">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-indigo-900 dark:text-indigo-300">Pro Pack</h3>
                <span className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">$79</span>
              </div>
              <ul className="space-y-2 mb-4">
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  30 Credits
                </li>
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  10 Applications
                </li>
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  Priority Support
                </li>
              </ul>
            </div>

            <div className="border border-indigo-100 dark:border-indigo-900 rounded-lg p-6 bg-indigo-50 dark:bg-indigo-900/30">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-indigo-900 dark:text-indigo-300">Enterprise Pack</h3>
                <span className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">$199</span>
              </div>
              <ul className="space-y-2 mb-4">
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  100 Credits
                </li>
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  33+ Applications
                </li>
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  VIP Support
                </li>
                <li className="flex items-center text-indigo-700 dark:text-indigo-300">
                  <span className="mr-2">•</span>
                  Bulk Discount
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-8">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">How to Purchase</h2>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            To purchase credits, please contact our support team. We'll help you choose the right plan and process your purchase manually.
          </p>
          
          <a
            href="mailto:support@blitzapply.com"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
          >
            <Mail className="h-4 w-4 mr-2" />
            Contact Support
          </a>
        </div>
      </div>
    </div>
  );
}