import { useAuth } from '../../hooks/useAuth';
import { PhotoUpload } from './PhotoUpload';
import { storage, db } from '../../lib/firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { CheckCircle2 } from 'lucide-react';
import toast from 'react-hot-toast';
import { EmailVerificationBanner } from '../EmailVerificationBanner';

export function StaffProfile() {
  const { user, userProfile } = useAuth();

  const handlePhotoUpload = async (file: File) => {
    if (!user) return;

    try {
      // Delete existing photo if any
      if (userProfile?.photoURL) {
        await handleDeletePhoto();
      }

      const fileExt = file.name.split('.').pop();
      const photoRef = ref(storage, `${user.uid}/photos/profile.${fileExt}`);
      await uploadBytes(photoRef, file);
      const photoURL = await getDownloadURL(photoRef);
      
      await updateDoc(doc(db, 'users', user.uid), {
        photoURL
      });

      toast.success('Profile picture updated successfully');
    } catch (error) {
      toast.error('Failed to upload profile picture');
    }
  };

  const handleDeletePhoto = async () => {
    if (!user || !userProfile?.photoURL) return;

    try {
      const photoPath = userProfile.photoURL.split('?')[0].split('/o/')[1];
      if (!photoPath) {
        throw new Error('Invalid photo URL');
      }

      const decodedPath = decodeURIComponent(photoPath);
      const photoRef = ref(storage, decodedPath);

      await deleteObject(photoRef);
      await updateDoc(doc(db, 'users', user.uid), {
        photoURL: null
      });
      
      toast.success('Profile picture deleted successfully');
    } catch (error: any) {
      if (error.code === 'storage/object-not-found') {
        try {
          await updateDoc(doc(db, 'users', user.uid), {
            photoURL: null
          });
          toast.success('Profile picture removed successfully');
        } catch (dbError) {
          toast.error('Failed to update profile');
        }
      } else {
        toast.error('Failed to delete profile picture');
      }
    }
  };

  if (!userProfile) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-3xl mx-auto space-y-6">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Profile Settings</h1>

        {!userProfile.isVerified && <EmailVerificationBanner />}

        <PhotoUpload
          photoURL={userProfile?.photoURL || null}
          firstName={userProfile?.firstName || ''}
          lastName={userProfile?.lastName || ''}
          onUpload={handlePhotoUpload}
          onDelete={handleDeletePhoto}
        />

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Personal Information</h2>
          
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  First Name
                </label>
                <div className="mt-1 px-4 h-12 flex items-center w-full rounded-md border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white">
                  {userProfile.firstName}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Last Name
                </label>
                <div className="mt-1 px-4 h-12 flex items-center w-full rounded-md border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white">
                  {userProfile.lastName}
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Email
              </label>
              <div className="mt-1 flex items-center">
                <div className="px-4 h-12 flex items-center flex-1 rounded-md border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white">
                  {user?.email}
                </div>
                {userProfile.isVerified && (
                  <CheckCircle2 className="h-5 w-5 text-green-500 ml-2" />
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Phone Number
              </label>
              <div className="mt-1 px-4 h-12 flex items-center w-full rounded-md border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white">
                {userProfile.phoneNumber}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}