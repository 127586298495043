import { useState, useEffect } from 'react';
import { useComments } from '../../hooks/useComments';
import { Comment } from '../../types/comment';
import { CommentList } from './CommentList';
import { CommentForm } from './CommentForm';
import { AlertCircle } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import toast from 'react-hot-toast';

interface CommentsProps {
  applicationId: string;
  onUpdate?: () => void;
}

export function Comments({ applicationId, onUpdate }: CommentsProps) {
  const { user } = useAuth();
  const { fetchComments, addComment } = useComments();
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user && applicationId) {
      loadComments();
    }
  }, [user, applicationId]);

  const loadComments = async () => {
    if (!user) {
      setError('Please sign in to view comments');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const fetchedComments = await fetchComments(applicationId);
      setComments(fetchedComments);
    } catch (error: any) {
      console.error('Error loading comments:', error);
      setError(error.message || 'Failed to load comments');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (message: string, files?: File[]) => {
    if (!user) {
      toast.error('Please sign in to comment');
      return;
    }

    try {
      setError(null);
      console.log('Submitting comment with files:', files?.length || 0);
      await addComment(applicationId, message, files);
      await loadComments();
      if (onUpdate) onUpdate();
    } catch (error: any) {
      console.error('Error adding comment:', error);
      toast.error(error.message || 'Failed to add comment');
      throw error;
    }
  };

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-md p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <p className="text-sm text-red-700 mt-1">{error}</p>
            <button
              onClick={loadComments}
              className="mt-2 text-sm font-medium text-red-600 hover:text-red-500"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <CommentForm onSubmit={handleSubmit} />
      <CommentList comments={comments} />
    </div>
  );
}