import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Application } from '../types/application';
import { ApplicationsTable } from '../components/tables/ApplicationsTable';
import { Tabs, TabsList, TabsTrigger } from '../components/ui/Tabs';

type FilterStatus = 'all' | 'in_progress' | 'pending' | 'resubmitted' | 'completed' | 'cancelled' | 'paid' | 'unpaid';

export function AgentApplications() {
  const { agentId } = useParams<{ agentId: string }>();
  const [applications, setApplications] = useState<Application[]>([]);
  const [filteredApplications, setFilteredApplications] = useState<Application[]>([]);
  const [currentFilter, setCurrentFilter] = useState<FilterStatus>('all');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadApplications = async () => {
      if (!agentId) return;

      try {
        setLoading(true);
        const applicationsQuery = query(
          collection(db, 'applications'),
          where('agentId', '==', agentId)
        );
        const snapshot = await getDocs(applicationsQuery);
        const apps = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          paymentStatus: doc.data().paymentStatus || 'pending'
        })) as Application[];
        setApplications(apps);
        filterApplications(apps, currentFilter);
      } catch (error) {
        console.error('Error loading applications:', error);
      } finally {
        setLoading(false);
      }
    };

    loadApplications();
  }, [agentId, currentFilter]);

  const filterApplications = (apps: Application[], filter: FilterStatus) => {
    let filtered = apps;
    switch (filter) {
      case 'in_progress':
        filtered = apps.filter(app => app.status === 'in_progress');
        break;
      case 'pending':
        filtered = apps.filter(app => app.status === 'pending');
        break;
      case 'completed':
        filtered = apps.filter(app => app.status === 'completed');
        break;
      case 'cancelled':
        filtered = apps.filter(app => app.status === 'cancelled');
        break;
      case 'paid':
        filtered = apps.filter(app => app.status === 'completed' && app.paymentStatus === 'paid');
        break;
      case 'unpaid':
        filtered = apps.filter(app => app.status === 'completed' && app.paymentStatus === 'unpaid');
        break;
      default:
        filtered = apps;
    }
    setFilteredApplications(filtered);
  };

  const handleFilterChange = (value: FilterStatus) => {
    setCurrentFilter(value);
    filterApplications(applications, value);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Agent Applications</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          View and manage all applications for this agent
        </p>
      </div>

      <Tabs 
        value={currentFilter} 
        onValueChange={(value) => handleFilterChange(value as FilterStatus)}
        className="w-full mb-6"
      >
        <TabsList className="w-full justify-start">
          <TabsTrigger value="all">
            All ({applications.length})
          </TabsTrigger>
          <TabsTrigger value="in_progress">
            In Progress ({applications.filter(app => app.status === 'in_progress').length})
          </TabsTrigger>
          <TabsTrigger value="pending">
            Pending ({applications.filter(app => app.status === 'pending').length})
          </TabsTrigger>
          <TabsTrigger value="completed">
            Completed ({applications.filter(app => app.status === 'completed').length})
          </TabsTrigger>
          <TabsTrigger value="cancelled">
            Cancelled ({applications.filter(app => app.status === 'cancelled').length})
          </TabsTrigger>
          <TabsTrigger value="paid">
            Paid ({applications.filter(app => app.status === 'completed' && app.paymentStatus === 'paid').length})
          </TabsTrigger>
          <TabsTrigger value="unpaid">
            Unpaid ({applications.filter(app => app.status === 'completed' && app.paymentStatus === 'unpaid').length})
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <ApplicationsTable 
        applications={filteredApplications}
        loading={loading}
        showPaymentStatus
      />
    </div>
  );
}