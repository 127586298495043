import { useState, useEffect } from 'react';
import { auth, db } from '../lib/firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  User
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { UserProfile } from '../types/user';
import { getFunctions, httpsCallable } from 'firebase/functions';

export type UserType = 'admin' | 'customer' | 'agent';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchUserProfile = async (uid: string) => {
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const profileData = docSnap.data() as UserProfile;
      
      // Check if email verification status needs updating
      if (auth.currentUser?.emailVerified && !profileData.isVerified) {
        await updateDoc(docRef, {
          isVerified: true
        });
        profileData.isVerified = true;
      }
      
      setUserProfile(profileData);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        await fetchUserProfile(user.uid);
        await checkAndSetAdminClaim(user);
      } else {
        setUserProfile(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);


  const signIn = async (email: string, password: string) => {
    const result = await signInWithEmailAndPassword(auth, email, password);
    
    // Check and set admin claim
    await checkAndSetAdminClaim(result.user);
    
    // Rest of your existing code...
    const docRef = doc(db, 'users', result.user.uid);
    const docSnap = await getDoc(docRef);
    const userData = docSnap.data() as UserProfile;
    
    if (userData.userType === 'agent' && userData.isKaput) {
      await firebaseSignOut(auth);
      throw new Error('Your account has been decommissioned. Please contact the administrator.');
    }
    
    toast.success('Signed in successfully');
    return result;
  };

  const signUp = async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    
    const userDoc = await getDoc(doc(db, 'users', result.user.uid));
    
    if (!userDoc.exists()) {
      const userData: UserProfile = {
        id: result.user.uid,
        firstName: result.user.displayName?.split(' ')[0] || '',
        lastName: result.user.displayName?.split(' ').slice(1).join(' ') || '',
        email: result.user.email || '',
        isVerified: true,
        userType: 'customer',
        createdAt: new Date().toISOString(),
        photoURL: result.user.photoURL,
        resumeURL: null,
        resumeFileName: null,
        credits: 50,
        applicationCount: 0
      };

      await setDoc(doc(db, 'users', result.user.uid), userData);
    }

    toast.success('Signed in successfully', {
      id: 'auth-success',
      duration: 3000
    });
    return result;
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
    toast.success('Signed out successfully', {
      id: 'auth-signout',
      duration: 3000
    });
  };

  const checkAndSetAdminClaim = async (user: User) => {
    try {
      // Get user's profile from Firestore
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data() as UserProfile;

      if (!userData) {
        return;
      }

      // Get current claims
      await user.getIdToken(true); // Force refresh before checking
      const token = await user.getIdTokenResult();
      const isCurrentlyAdmin = token.claims.admin === true;

      // If user is admin in Firestore but doesn't have the claim
      if (userData.userType === 'admin' && !isCurrentlyAdmin) {
        const functions = getFunctions();
        const setAdminClaim = httpsCallable(functions, 'setAdminClaim');
        await setAdminClaim({ uid: user.uid });
        
        // Force token refresh
        await user.getIdToken(true);
      }
    } catch (error) {
      // Log error without exposing sensitive data
      console.error('Error checking admin status');
    }
  };

  return {
    user,
    userProfile,
    loading,
    signIn,
    signUp,
    signOut,
    signInWithGoogle,
    checkVerification: async () => {
      if (user) {
        await user.reload();
        if (user.emailVerified) {
          const docRef = doc(db, 'users', user.uid);
          await updateDoc(docRef, {
            isVerified: true
          });
          // Update local state and refetch profile
          await fetchUserProfile(user.uid);
        }
      }
    },
    fetchUserProfile,
  };
}