import { LucideIcon } from 'lucide-react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';
import { cn } from '../../utils/cn';

interface StatsCardProps {
  title: string;
  value: string;
  icon?: LucideIcon | IconType;
  linkTo?: string;
  variant?: 'primary' | 'success' | 'warning' | 'danger' | 'info';
  iconClassName?: string;
}

const variants = {
  primary: 'text-indigo-600 dark:text-indigo-400',
  success: 'text-green-600 dark:text-green-400',
  warning: 'text-yellow-600 dark:text-yellow-400',
  danger: 'text-red-600 dark:text-red-400',
  info: 'text-blue-600 dark:text-blue-400'
};

export function StatsCard({ 
  title, 
  value, 
  icon: Icon, 
  linkTo, 
  variant = 'primary',
  iconClassName 
}: StatsCardProps) {
  const CardContent = () => (
    <div className="p-5">
      <div className="flex items-center">
        {Icon && (
          <div className="flex-shrink-0">
            <Icon className={cn('h-6 w-6', iconClassName || variants[variant])} />
          </div>
        )}
        <div className={cn('flex-1', Icon && 'ml-5')}>
          <dl>
            <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
              {title}
            </dt>
            <dd className={cn('text-3xl font-semibold', variants[variant])}>
              {value}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );

  if (linkTo) {
    return (
      <Link to={linkTo} className="block bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow">
        <CardContent />
      </Link>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg">
      <CardContent />
    </div>
  );
}