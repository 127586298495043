import { useState, useEffect } from 'react';
import { Comment } from '../../types/comment';
import { formatDate } from '../../utils/date';
import { db } from '../../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { User, ExternalLink } from 'lucide-react';

interface CommentListProps {
  comments: Comment[];
}

interface UserProfile {
  firstName: string;
  lastName: string;
  photoURL?: string;
  userType?: string;
}

export function CommentList({ comments }: CommentListProps) {
  const [userProfiles, setUserProfiles] = useState<Record<string, UserProfile>>({});

  useEffect(() => {
    const fetchUserProfiles = async () => {
      const uniqueUserIds = [...new Set(comments.map(comment => comment.userId))];
      const profiles: Record<string, UserProfile> = {};

      await Promise.all(
        uniqueUserIds.map(async (userId) => {
          try {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
              const data = userDoc.data();
              profiles[userId] = {
                firstName: data.firstName,
                lastName: data.lastName,
                photoURL: data.photoURL,
                userType: data.userType
              };
            }
          } catch (error) {
            console.error(`Error fetching user profile for ${userId}:`, error);
          }
        })
      );

      setUserProfiles(profiles);
    };

    if (comments.length > 0) {
      fetchUserProfiles();
    }
  }, [comments]);

  const getFileIcon = (type: string) => {
    if (type.startsWith('image/')) return '🖼️';
    if (type === 'application/pdf') return '📄';
    if (type.includes('word')) return '📝';
    return '📎';
  };

  const isImageFile = (type: string) => type.startsWith('image/');

  const getUserLabel = (userId: string, userType: string) => {
    const profile = userProfiles[userId];
    if (!profile) return 'Unknown User';

    let label = `${profile.firstName} ${profile.lastName}`;
    if (profile.userType === 'admin') {
      label += ' (Admin)';
    } else if (userType === 'agent') {
      label += ' (Agent)';
    }
    return label;
  };

  if (comments.length === 0) {
    return (
      <p className="text-center text-gray-500 dark:text-gray-400 py-4">No comments yet</p>
    );
  }

  return (
    <div className="space-y-4">
      {comments.map((comment) => {
        const profile = userProfiles[comment.userId];
        const isAgent = comment.userType === 'agent';
        const isAdmin = profile?.userType === 'admin';

        return (
          <div
            key={comment.id}
            className={`p-4 rounded-lg ${
              isAdmin 
                ? 'bg-purple-50 dark:bg-purple-900/30 border border-purple-100 dark:border-purple-800/30'
                : isAgent 
                  ? 'bg-indigo-50 dark:bg-indigo-900/30 border border-indigo-100 dark:border-indigo-800/30' 
                  : 'bg-gray-50 dark:bg-gray-800/30 border border-gray-100 dark:border-gray-700'
            }`}
          >
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0">
                {profile?.photoURL ? (
                  <img
                    src={profile.photoURL}
                    alt={`${profile.firstName} ${profile.lastName}`}
                    className="h-8 w-8 rounded-full object-cover"
                  />
                ) : (
                  <div className="h-8 w-8 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                    <User className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                  </div>
                )}
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                  <p className={`text-sm font-medium ${
                    isAdmin 
                      ? 'text-purple-700 dark:text-purple-300'
                      : isAgent 
                        ? 'text-indigo-700 dark:text-indigo-300' 
                        : 'text-gray-900 dark:text-gray-200'
                  }`}>
                    {getUserLabel(comment.userId, comment.userType)}
                  </p>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {formatDate(comment.createdAt)}
                  </span>
                </div>
                {comment.message && (
                  <p className="mt-1 text-sm text-gray-700 dark:text-gray-200 whitespace-pre-wrap">
                    {comment.message}
                  </p>
                )}
                {comment.attachments && comment.attachments.length > 0 && (
                  <div className="mt-3">
                    {/* Image Grid */}
                    {comment.attachments.some(file => isImageFile(file.type)) && (
                      <div className="grid grid-cols-2 gap-2 mb-2">
                        {comment.attachments
                          .filter(file => isImageFile(file.type))
                          .map((file) => (
                            <div key={file.id} className="relative group">
                              <img
                                src={file.url}
                                alt={file.name}
                                className="w-full h-48 object-cover rounded-lg"
                              />
                              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-50 transition-opacity rounded-lg">
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="p-2 bg-white dark:bg-gray-800 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                                  onClick={(e) => e.stopPropagation()}
                                  title="Open in new tab"
                                >
                                  <ExternalLink className="h-5 w-5 text-gray-700 dark:text-gray-200" />
                                </a>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    
                    {/* Other Files */}
                    <div className="space-y-2">
                      {comment.attachments
                        .filter(file => !isImageFile(file.type))
                        .map((file) => (
                          <a
                            key={file.id}
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center p-2 bg-white dark:bg-gray-700/50 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700 group"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <span className="mr-2">{getFileIcon(file.type)}</span>
                            <span className="flex-1 text-sm text-gray-700 dark:text-gray-200 truncate">
                              {file.name}
                            </span>
                            <span className="text-xs text-gray-500 dark:text-gray-400 mx-2">
                              ({(file.size / 1024).toFixed(1)} KB)
                            </span>
                            <ExternalLink className="h-4 w-4 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300" />
                          </a>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}