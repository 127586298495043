import { useRef, useState } from 'react';
import { Upload, Trash2 } from 'lucide-react';
import { ProfilePicture } from '../ui/ProfilePicture';

interface PhotoUploadProps {
  photoURL: string | null;
  firstName?: string;
  lastName?: string;
  onUpload: (file: File) => Promise<void>;
  onDelete: () => Promise<void>;
}

export function PhotoUpload({ photoURL, firstName = '', lastName = '', onUpload, onDelete }: PhotoUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    
    try {
      setUploading(true);
      await onUpload(e.target.files[0]);
    } finally {
      setUploading(false);
      // Clear the input to allow uploading the same file again
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mb-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Profile Picture</h2>
        <div className="flex space-x-2">
          <button
            onClick={() => fileInputRef.current?.click()}
            disabled={uploading}
            className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
          >
            <Upload className="h-4 w-4 mr-2" />
            Upload Photo
          </button>
          {photoURL && (
            <button
              onClick={onDelete}
              disabled={uploading}
              className="inline-flex items-center px-4 py-2 border border-red-300 dark:border-red-500 rounded-md shadow-sm text-sm font-medium text-red-700 dark:text-red-400 bg-white dark:bg-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 disabled:opacity-50"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete
            </button>
          )}
        </div>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={handleFileChange}
      />

      {uploading && (
        <div className="mb-4 text-sm text-gray-600 dark:text-gray-400">
          Uploading photo...
        </div>
      )}

      <div className="flex justify-center">
        <ProfilePicture
          photoURL={photoURL}
          firstName={firstName}
          lastName={lastName}
          size="xl"
        />
      </div>
    </div>
  );
}