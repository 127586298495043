import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { db, storage } from '../lib/firebase';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { Plus, Pencil, Trash2 } from 'lucide-react';
import { FaRegFilePdf } from 'react-icons/fa6';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';
import toast from 'react-hot-toast';

interface JobProfile {
  id: string;
  profileName: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber: string;
  resumeURL?: string;
  resumeFileName?: string;
  createdAt: string;
}

export function JobProfiles() {
  const { user } = useAuth();
  const [profiles, setProfiles] = useState<JobProfile[]>([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [profileToDelete, setProfileToDelete] = useState<JobProfile | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    loadProfiles();
  }, [user]);

  async function loadProfiles() {
    if (!user) return;

    try {
      const q = query(
        collection(db, 'jobProfiles'),
        where('userId', '==', user.uid)
      );
      const querySnapshot = await getDocs(q);
      const profilesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as JobProfile[];
      
      setProfiles(profilesList);
    } catch (error) {
      console.error('Error loading profiles:', error);
      toast.error('Failed to load profiles');
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteConfirm = async () => {
    if (!user || !profileToDelete) return;

    try {
      setDeleting(profileToDelete.id);
      
      // Delete resume from storage if exists
      if (profileToDelete.resumeURL) {
        const resumePath = profileToDelete.resumeURL.split('?')[0].split('/o/')[1];
        if (resumePath) {
          const decodedPath = decodeURIComponent(resumePath);
          const resumeRef = ref(storage, decodedPath);
          try {
            await deleteObject(resumeRef);
          } catch (error) {
            console.error('Error deleting resume:', error);
          }
        }
      }

      // Delete profile document and update user's jobProfiles
      await deleteDoc(doc(db, 'jobProfiles', profileToDelete.id));
      await updateDoc(doc(db, 'users', user.uid), {
        [`userJobProfiles.${profileToDelete.id}`]: null
      });
      
      // Update local state
      setProfiles(prev => prev.filter(p => p.id !== profileToDelete.id));
      toast.success('Profile deleted successfully');
    } catch (error) {
      console.error('Error deleting profile:', error);
      toast.error('Failed to delete profile');
    } finally {
      setDeleting(null);
      setProfileToDelete(null);
      setShowDeleteModal(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="mb-8 flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Job Profiles</h1>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            Manage your job profiles and resumes
          </p>
        </div>
        <Link
          to="/job-profiles/new"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
        >
          <Plus className="h-5 w-5 mr-2" />
          New Job Profile
        </Link>
      </div>

      {profiles.length === 0 ? (
        <div className="text-center py-12 bg-white dark:bg-gray-800 shadow rounded-lg">
          <div className="p-12">
            <Plus className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" />
            <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">No job profiles</h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Get started by creating a new job profile.
            </p>
            <div className="mt-6">
              <Link
                to="/job-profiles/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
              >
                <Plus className="h-5 w-5 mr-2" />
                New Job Profile
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg divide-y divide-gray-200 dark:divide-gray-700">
          {profiles.map((profile) => (
            <div
              key={profile.id}
              className="p-6 flex items-center justify-between"
            >
              <div className="flex-1 min-w-0">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  {profile.profileName}
                </h2>
                {profile.resumeURL && profile.resumeFileName && (
                  <a
                    href={profile.resumeURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-sm text-gray-600 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 group"
                  >
                    <FaRegFilePdf className="h-6 w-6 text-red-500 dark:text-red-400 mr-2 group-hover:text-red-600 dark:group-hover:text-red-300" />
                    {profile.resumeFileName}
                  </a>
                )}
              </div>
              <div className="flex items-center space-x-4">
                <Link
                  to={`/job-profiles/${profile.id}/edit`}
                  className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
                >
                  <Pencil className="h-5 w-5" />
                </Link>
                <button
                  onClick={() => {
                    setProfileToDelete(profile);
                    setShowDeleteModal(true);
                  }}
                  disabled={deleting === profile.id}
                  className="text-gray-400 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400 disabled:opacity-50"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteConfirm}
        title="Delete Job Profile"
        message="Are you sure you want to delete this job profile? This action cannot be undone."
      />
    </div>
  );
}