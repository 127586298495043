import { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { UserProfile } from '../types/user';
import { AgentsTable } from '../components/tables/AgentsTable';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import toast from 'react-hot-toast';

export function AdminAgents() {
  const [agents, setAgents] = useState<(UserProfile & { id: string })[]>([]);
  const [, setLoading] = useState(true);

  const loadAgents = async () => {
    try {
      setLoading(true);
      const agentsQuery = query(collection(db, 'users'));
      const querySnapshot = await getDocs(agentsQuery);
      
      const agentsList = querySnapshot.docs
        .map(doc => ({
          ...doc.data() as UserProfile,
          id: doc.id
        }))
        .filter(user => user.userType === 'agent')
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      setAgents(agentsList);
    } catch (error) {
      console.error('Error loading agents:', error);
      toast.error('Failed to load agents');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAgents();
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Link
        to="/admin/dashboard"
        className="flex items-center text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white mb-6"
      >
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Dashboard
      </Link>

      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Agents</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Manage and monitor all agents in the system
        </p>
      </div>

      <AgentsTable 
        agents={agents}
        onAgentUpdated={loadAgents}
        pageSize={10}
      />
    </div>
  );
}