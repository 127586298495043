import { useCallback } from 'react';
import { useAuth } from './useAuth';
import { db, storage } from '../lib/firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Comment } from '../types/comment';
import toast from 'react-hot-toast';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'application/pdf',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export function useComments() {
  const { user, userProfile } = useAuth();

  const fetchComments = useCallback(async (applicationId: string): Promise<Comment[]> => {
    if (!user) {
      throw new Error('Please sign in to view comments');
    }

    try {
      const appRef = doc(db, 'applications', applicationId);
      const appSnap = await getDoc(appRef);
      
      if (!appSnap.exists()) {
        throw new Error('Application not found');
      }

      const application = appSnap.data();
      return application.comments || [];
    } catch (error: any) {
      console.error('Error fetching comments:', error);
      if (error.code === 'permission-denied') {
        throw new Error('You do not have permission to view comments');
      }
      throw new Error('Failed to load comments');
    }
  }, [user]);

  const uploadAttachment = async (applicationId: string, file: File) => {
    if (!user) throw new Error('User not authenticated');

    if (file.size > MAX_FILE_SIZE) {
      throw new Error(`File ${file.name} exceeds 5MB limit`);
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      throw new Error(`File type ${file.type} is not supported`);
    }

    try {
      console.log('Starting file upload:', {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        applicationId
      });

      const fileId = crypto.randomUUID();
      const fileExt = file.name.split('.').pop() || '';
      const fileName = `${fileId}.${fileExt}`;
      const storagePath = `${user.uid}/comments/${applicationId}/${fileName}`;
      
      console.log('Storage path:', storagePath);
      
      // Create storage reference
      const fileRef = ref(storage, storagePath);
      console.log('Storage reference created');

      // Upload file
      console.log('Uploading file...');
      const uploadResult = await uploadBytes(fileRef, file);
      console.log('Upload completed:', uploadResult);

      // Get download URL
      console.log('Getting download URL...');
      const url = await getDownloadURL(fileRef);
      console.log('Download URL received:', url);

      const attachment = {
        id: fileId,
        name: file.name,
        url,
        type: file.type,
        size: file.size
      };

      console.log('Attachment object created:', attachment);
      return attachment;
    } catch (error: any) {
      console.error('Error uploading file:', {
        error,
        code: error.code,
        message: error.message,
        fileName: file.name,
        storagePath: `${user.uid}/comments/${applicationId}/${file.name}`
      });
      
      if (error.code === 'storage/unauthorized') {
        throw new Error('You do not have permission to upload files');
      }
      
      throw new Error(`Failed to upload ${file.name}: ${error.message}`);
    }
  };

  const addComment = useCallback(async (applicationId: string, message: string, files?: File[]) => {
    if (!user || !userProfile) {
      throw new Error('Please sign in to comment');
    }

    if (!message.trim() && (!files || files.length === 0)) {
      throw new Error('Comment cannot be empty');
    }

    if (message.length > 1000) {
      throw new Error('Comment is too long (maximum 1000 characters)');
    }

    if (files && files.length > 5) {
      throw new Error('Maximum 5 files per comment');
    }

    try {
      const appRef = doc(db, 'applications', applicationId);
      const appSnap = await getDoc(appRef);
      
      if (!appSnap.exists()) {
        throw new Error('Application not found');
      }

      const application = appSnap.data();
      
      if (application.status === 'cancelled' || application.status === 'completed') {
        throw new Error('Cannot add comments to a completed or cancelled application');
      }

      // Upload attachments first if any
      let attachments;
      if (files && files.length > 0) {
        console.log('Starting attachments upload:', {
          fileCount: files.length,
          applicationId
        });

        try {
          attachments = await Promise.all(
            files.map(file => uploadAttachment(applicationId, file))
          );
          console.log('All attachments uploaded successfully:', attachments);
        } catch (error: any) {
          console.error('Error uploading attachments:', error);
          throw new Error(`Failed to upload attachments: ${error.message}`);
        }
      }

      // Create comment object
      const comment: Comment = {
        id: crypto.randomUUID(),
        userId: user.uid,
        userType: userProfile.userType === 'agent' ? 'agent' : 'customer',
        message: message.trim(),
        createdAt: new Date().toISOString()
      };

      // Add attachments if any were uploaded
      if (attachments && attachments.length > 0) {
        comment.attachments = attachments;
      }

      console.log('Adding comment to Firestore:', {
        applicationId,
        comment
      });

      // Update the application document
      await updateDoc(appRef, {
        comments: arrayUnion(comment),
        lastActionBy: user.uid,
        lastActionAt: new Date().toISOString()
      });

      console.log('Comment added successfully');
      toast.success('Comment added successfully');
      return comment;
    } catch (error: any) {
      console.error('Error adding comment:', {
        error,
        code: error.code,
        message: error.message,
        applicationId
      });

      if (error.code === 'permission-denied') {
        throw new Error('You do not have permission to comment on this application');
      }
      throw error;
    }
  }, [user, userProfile]);

  return {
    fetchComments,
    addComment
  };
}