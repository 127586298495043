
export const sections = [
  { id: 'details', name: 'Application Details' },
  { id: 'profile', name: 'Job Profile' },
  { id: 'comments', name: 'Comments' }
];

interface SectionNavProps {
  activeSection: string | null;
  onSectionClick: (sectionId: string) => void;
}

export function SectionNav({ activeSection, onSectionClick }: SectionNavProps) {
  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg mb-8">
      <nav className="px-4 sm:px-6 lg:px-8" aria-label="Application sections">
        <div className="border-b border-gray-200 dark:border-gray-700">
          <div className="-mb-px flex space-x-8">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => onSectionClick(section.id)}
                className={`
                  py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap
                  ${activeSection === section.id
                    ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400 dark:border-indigo-400'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-600'
                  }
                `}
              >
                {section.name}
              </button>
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
}