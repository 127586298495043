import { Application } from '../../types/application';
import { ApplicationCard } from './ApplicationCard';

interface ApplicationListProps {
  applications: Application[];
  onRefresh: () => void;
}

export function ApplicationList({ applications, onRefresh }: ApplicationListProps) {
  // Group applications by status for better organization
  const groupedApplications = applications.reduce((acc, app) => {
    const status = app.status;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(app);
    return acc;
  }, {} as Record<string, Application[]>);

  // Define status order for display
  const statusOrder = ['submitted', 'in_progress', 'pending', 'resubmitted', 'completed', 'cancelled'];

  return (
    <div className="space-y-8">
      {statusOrder.map(status => {
        const statusApplications = groupedApplications[status];
        if (!statusApplications?.length) return null;

        return (
          <div key={status} className="space-y-4">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white capitalize">
              {status.replace('_', ' ')} Applications
            </h2>
            <div className="space-y-4">
              {statusApplications.map(application => (
                <ApplicationCard
                  key={application.id}
                  application={application}
                  onRefresh={onRefresh}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}