import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { Logo } from './layout/Logo';
import { AuthLinks } from './layout/AuthLinks';
import { NavLinks } from './layout/NavLinks';
import { DarkModeToggle } from './layout/DarkModeToggle';

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, userProfile } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (user) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (location.pathname === '/' && user) {
      navigate('/dashboard');
    }
  }, [location.pathname, user, navigate]);

  return (
    <nav className="bg-gray-900 dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Logo onClick={handleLogoClick} />
          </div>

          <div className="hidden sm:flex sm:items-center sm:ml-6">
            {!user ? (
              <div className="flex items-center">
                <AuthLinks />
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <NavLinks userProfile={userProfile} />
                <div className="h-6 w-px bg-gray-700" />
                <DarkModeToggle />
              </div>
            )}
          </div>

          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              {isOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            {!user ? (
              <AuthLinks isMobile />
            ) : (
              <NavLinks userProfile={userProfile} isMobile />
            )}
          </div>
        </div>
      )}
    </nav>
  );
}