import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, writeBatch, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Application } from '../types/application';
import { useAuth } from '../hooks/useAuth';
import { Button } from '../components/ui';
import { toast } from 'react-hot-toast';
import { PaymentConfirmationDialog } from '../components/dialogs/PaymentConfirmationDialog';

export function MakePayment() {
  const { agentId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [applications, setApplications] = useState<Application[]>([]);
  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const loadUnpaidApplications = async () => {
      if (!agentId) return;

      try {
        const applicationsQuery = query(
          collection(db, 'applications'),
          where('agentId', '==', agentId),
          where('status', '==', 'completed'),
          where('paymentStatus', '!=', 'paid')
        );
        const snapshot = await getDocs(applicationsQuery);
        const apps = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Application[];
        setApplications(apps);
      } catch (error) {
        console.error('Error loading applications:', error);
        toast.error('Failed to load applications');
      } finally {
        setLoading(false);
      }
    };

    loadUnpaidApplications();
  }, [agentId]);

  const handlePaymentClick = () => {
    if (selectedApps.length > 0) {
      setShowConfirmation(true);
    }
  };

  const handlePaymentConfirm = async () => {
    if (!user || selectedApps.length === 0) return;

    try {
      setProcessing(true);
      const batch = writeBatch(db);
      
      // Create payment record
      const paymentRef = doc(collection(db, 'payments'));
      batch.set(paymentRef, {
        agentId,
        applications: selectedApps,
        amount: selectedApps.length * 3,
        date: new Date().toISOString(),
        createdBy: user.uid,
        createdAt: new Date().toISOString()
      });

      // Update application payment statuses
      selectedApps.forEach(appId => {
        batch.update(doc(db, 'applications', appId), {
          paymentStatus: 'paid',
          paymentDate: new Date().toISOString(),
          paymentAmount: 3
        });
      });

      await batch.commit();
      toast.success('Payment recorded successfully');
      navigate(`/admin/agents/${agentId}`);
    } catch (error) {
      console.error('Error recording payment:', error);
      toast.error('Failed to record payment');
    } finally {
      setProcessing(false);
      setShowConfirmation(false);
    }
  };

  if (loading) {
    return <div className="animate-pulse">Loading...</div>;
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="mb-8">
          <h1 className="text-3xl font-bold">Record Payment</h1>
          <p className="text-gray-600">Select applications to record payment</p>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    checked={selectedApps.length === applications.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedApps(applications.map(app => app.id));
                      } else {
                        setSelectedApps([]);
                      }
                    }}
                    className="rounded border-gray-300 dark:border-gray-600"
                  />
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Company
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Position
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Completed Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {applications.map(app => (
                <tr key={app.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={selectedApps.includes(app.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedApps([...selectedApps, app.id]);
                        } else {
                          setSelectedApps(selectedApps.filter(id => id !== app.id));
                        }
                      }}
                      className="rounded border-gray-300 dark:border-gray-600"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{app.company}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{app.position}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {app.completedAt ? new Date(app.completedAt).toLocaleDateString() : 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">$3.00</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-6 flex justify-between items-center">
          <div className="text-gray-600 dark:text-gray-400">
            Selected: {selectedApps.length} applications
            <span className="ml-2 font-medium">
              (Total: ${selectedApps.length * 3})
            </span>
          </div>
          <Button
            onClick={handlePaymentClick}
            disabled={selectedApps.length === 0 || processing}
            className="bg-indigo-600 text-white px-6"
          >
            Record Payment
          </Button>
        </div>
      </div>

      <PaymentConfirmationDialog
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handlePaymentConfirm}
        selectedCount={selectedApps.length}
        totalAmount={selectedApps.length * 3}
        processing={processing}
      />
    </>
  );
} 