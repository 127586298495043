import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { db } from '../lib/firebase';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { Application } from '../types/application';
import { ApplicationsTable } from '../components/tables/ApplicationsTable';
import toast from 'react-hot-toast';

export function AdminApplications() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get('status') || 'all';
  
  const [applications, setApplications] = useState<Application[]>([]);
  const [, setLoading] = useState(true);

  useEffect(() => {
    loadApplications();
  }, []);

  const loadApplications = async () => {
    try {
      setLoading(true);
      const applicationsRef = collection(db, 'applications');
      const q = query(applicationsRef, orderBy('updatedAt', 'desc'));
      const snapshot = await getDocs(q);
      const apps = await Promise.all(snapshot.docs.map(async (doc) => {
        const data = doc.data() as Application;
        const fulfillmentTime = data.completedAt ? 
          new Date(data.completedAt).getTime() - new Date(data.createdAt).getTime() : 
          undefined;
        return {
          ...data,
          id: doc.id,
          fulfillmentTime
        };
      }));

      setApplications(apps);
    } catch (error) {
      console.error('Error loading applications:', error);
      toast.error('Failed to load applications');
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (value: string) => {
    navigate(`/admin/applications?status=${value}`);
  };

  const getFilteredApplications = () => {
    if (statusFilter === 'all') return applications;
    return applications.filter(app => app.status === statusFilter);
  };

  const getStatusCounts = () => {
    return {
      all: applications.length,
      submitted: applications.filter(a => a.status === 'submitted').length,
      in_progress: applications.filter(a => a.status === 'in_progress').length,
      pending: applications.filter(a => a.status === 'pending').length,
      completed: applications.filter(a => a.status === 'completed').length,
      cancelled: applications.filter(a => a.status === 'cancelled').length,
      resubmitted: applications.filter(a => a.status === 'resubmitted').length
    };
  };

  const statusCounts = getStatusCounts();

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">All Applications</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Monitor and manage all applications in the system
        </p>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-2 mb-6">
        <div className="flex flex-wrap gap-2">
          <button 
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              statusFilter === 'all' 
                ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
            }`}
            onClick={() => handleTabChange('all')}
          >
            All ({statusCounts.all})
          </button>
          <button 
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              statusFilter === 'submitted' 
                ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
            }`}
            onClick={() => handleTabChange('submitted')}
          >
            Submitted ({statusCounts.submitted})
          </button>
          <button 
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              statusFilter === 'in_progress' 
                ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
            }`}
            onClick={() => handleTabChange('in_progress')}
          >
            In Progress ({statusCounts.in_progress})
          </button>
          <button 
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              statusFilter === 'pending' 
                ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
            }`}
            onClick={() => handleTabChange('pending')}
          >
            Pending ({statusCounts.pending})
          </button>
          <button 
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              statusFilter === 'resubmitted' 
                ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
            }`}
            onClick={() => handleTabChange('resubmitted')}
          >
            Resubmitted ({statusCounts.resubmitted})
          </button>
          <button 
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              statusFilter === 'completed' 
                ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
            }`}
            onClick={() => handleTabChange('completed')}
          >
            Completed ({statusCounts.completed})
          </button>
          <button 
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              statusFilter === 'cancelled' 
                ? 'bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
            }`}
            onClick={() => handleTabChange('cancelled')}
          >
            Cancelled ({statusCounts.cancelled})
          </button>
        </div>
      </div>

      <ApplicationsTable applications={getFilteredApplications()} />
    </div>
  );
}