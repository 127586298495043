import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, userProfile, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  // Redirect users to their appropriate dashboards if they try to access the wrong routes
  if (userProfile) {
    switch (userProfile.userType) {
      case 'agent':
        if (window.location.pathname === '/dashboard') {
          return <Navigate to="/agent/dashboard" />;
        }
        break;
      case 'customer':
        if (window.location.pathname.startsWith('/admin') || window.location.pathname.startsWith('/agent')) {
          return <Navigate to="/dashboard" />;
        }
        break;
    }
  }

  return <>{children}</>;
}