import { AlertCircle } from 'lucide-react';

export function PendingApplicationBanner() {
  return (
    <div className="bg-yellow-50 p-4 rounded-md mb-6">
      <div className="flex">
        <AlertCircle className="h-5 w-5 text-yellow-400" />
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Action Required
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              You have pending applications that require additional information. Please check the comments section of your applications and provide the requested information to continue the process.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}