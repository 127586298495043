import { Link } from 'react-router-dom';

export function Privacy() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Privacy Policy</h1>
          <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">1. Introduction</h2>
              <p className="text-gray-600 dark:text-gray-400">
                BlitzApply ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our job application assistance service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">2. Information We Collect</h2>
              
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">2.1 Personal Information</h3>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Name, email address, and phone number</li>
                    <li>Professional information (resume, work history, skills)</li>
                    <li>Payment information (processed securely through our payment providers)</li>
                    <li>Profile photos (optional)</li>
                    <li>Communication preferences</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">2.2 Application Data</h3>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Job application details and history</li>
                    <li>Communication with agents</li>
                    <li>Application status and progress</li>
                    <li>Documents submitted for job applications</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">2.3 Technical Data</h3>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>IP address and device information</li>
                    <li>Browser type and version</li>
                    <li>Usage data and interaction with our service</li>
                    <li>Cookies and similar tracking technologies</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">3. How We Use Your Information</h2>
              
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">3.1 Core Service Functionality</h3>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Process and submit job applications on your behalf</li>
                    <li>Match you with appropriate agents</li>
                    <li>Manage your account and credits</li>
                    <li>Provide customer support</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">3.2 Service Improvement</h3>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Analyze and improve our service quality</li>
                    <li>Develop new features and functionality</li>
                    <li>Monitor and prevent technical issues</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">3.3 Communication</h3>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Send application status updates</li>
                    <li>Provide important service notifications</li>
                    <li>Share relevant job opportunities (with your consent)</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">4. Information Sharing</h2>
              <p className="text-gray-600 dark:text-gray-400 mb-3">
                We share your information only in specific circumstances:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                <li>With agents processing your applications (limited to necessary information)</li>
                <li>With job platforms when submitting applications</li>
                <li>With service providers who assist our operations</li>
                <li>When required by law or to protect our rights</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">5. Data Security</h2>
              <p className="text-gray-600 dark:text-gray-400 mb-3">
                We implement appropriate security measures including:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                <li>Encryption of sensitive data</li>
                <li>Secure authentication systems</li>
                <li>Regular security assessments</li>
                <li>Employee access controls</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">6. Your Rights</h2>
              <p className="text-gray-600 dark:text-gray-400 mb-3">
                You have the right to:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                <li>Access your personal information</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Opt-out of marketing communications</li>
                <li>Export your data</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">7. Contact Us</h2>
              <p className="text-gray-600 dark:text-gray-400">
                If you have questions about this Privacy Policy, please{' '}
                <Link to="/contact" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  contact us
                </Link>.
              </p>
            </section>

            <div className="mt-12 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                By using BlitzApply, you agree to this Privacy Policy and our{' '}
                <Link to="/terms" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  Terms of Service
                </Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 