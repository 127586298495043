import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';

const tiers = [
  {
    name: 'Starter',
    price: 29,
    credits: 10,
    description: 'Perfect for testing the waters with a few applications.',
    features: [
      '10 Application Credits',
      'Expert Agent Processing',
      'Application Status Tracking',
      'Basic Support',
      '30-day Credit Validity'
    ]
  },
  {
    name: 'Pro',
    price: 79,
    credits: 30,
    description: 'Most popular for active job seekers.',
    features: [
      '30 Application Credits',
      'Priority Agent Processing',
      'Advanced Status Tracking',
      'Priority Support',
      '60-day Credit Validity',
      'Application Analytics'
    ]
  },
  {
    name: 'Enterprise',
    price: 199,
    credits: 100,
    description: 'Best value for serious job hunters.',
    features: [
      '100 Application Credits',
      'VIP Agent Processing',
      'Premium Status Tracking',
      '24/7 Priority Support',
      '90-day Credit Validity',
      'Advanced Analytics',
      'Personalized Strategy Session'
    ]
  }
];

export function Pricing() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 dark:text-white sm:text-center">
            Simple, Transparent Pricing
          </h1>
          <p className="mt-5 text-xl text-gray-500 dark:text-gray-400 sm:text-center">
            Choose the perfect plan for your job search journey
          </p>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800"
            >
              <div className="p-6">
                <h2 className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white">
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                  {tier.description}
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900 dark:text-white">
                    ${tier.price}
                  </span>
                  <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                    /package
                  </span>
                </p>
                <p className="mt-2 text-sm text-indigo-600 dark:text-indigo-400 font-semibold">
                  {tier.credits} Credits ({Math.round(tier.credits / 3)} Applications)
                </p>
                <Link
                  to="/register"
                  className="mt-8 block w-full bg-indigo-600 dark:bg-indigo-500 hover:bg-indigo-700 dark:hover:bg-indigo-600 text-white font-medium py-2 px-4 rounded-md text-center"
                >
                  Get Started
                </Link>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-sm font-medium text-gray-900 dark:text-white tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-6 space-y-4">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-16 sm:text-center">
          <p className="text-gray-500 dark:text-gray-400">
            Need a custom plan? {' '}
            <Link to="/contact" className="text-indigo-600 dark:text-indigo-400 hover:underline">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
} 