import { Link } from 'react-router-dom';

export function GDPR() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">GDPR Compliance</h1>
          <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">1. Introduction</h2>
              <p className="text-gray-600 dark:text-gray-400">
                BlitzApply is committed to protecting the privacy and rights of our users under the General Data Protection Regulation (GDPR). This policy explains how we comply with GDPR requirements in collecting, processing, and protecting your personal data.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">2. Your Rights Under GDPR</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  Under GDPR, you have the following rights:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>Right to access your personal data</li>
                  <li>Right to rectification of inaccurate data</li>
                  <li>Right to erasure ("right to be forgotten")</li>
                  <li>Right to restrict processing</li>
                  <li>Right to data portability</li>
                  <li>Right to object to processing</li>
                  <li>Rights related to automated decision making</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">3. Data We Collect</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">3.1 Personal Information</h3>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Name and contact details</li>
                    <li>Professional information (resume, work history)</li>
                    <li>Account credentials</li>
                    <li>Communication preferences</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">3.2 Usage Data</h3>
                  <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                    <li>Application history and status</li>
                    <li>Service interaction data</li>
                    <li>Technical information (IP address, browser type)</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">4. Legal Basis for Processing</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  We process your data under the following legal bases:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>Contract fulfillment (service provision)</li>
                  <li>Legal obligations</li>
                  <li>Legitimate interests</li>
                  <li>Consent (where specifically requested)</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">5. Data Protection Measures</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  We implement appropriate technical and organizational measures:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>Encryption of personal data</li>
                  <li>Regular security assessments</li>
                  <li>Access controls and authentication</li>
                  <li>Staff training on data protection</li>
                  <li>Data processing agreements with third parties</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">6. International Data Transfers</h2>
              <p className="text-gray-600 dark:text-gray-400">
                When we transfer data outside the EEA, we ensure appropriate safeguards are in place through:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2 mt-3">
                <li>Standard contractual clauses</li>
                <li>Adequacy decisions</li>
                <li>Data processing agreements</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">7. Data Retention</h2>
              <p className="text-gray-600 dark:text-gray-400">
                We retain personal data only for as long as necessary for the purposes for which it was collected, or as required by applicable laws. When data is no longer needed, it is securely deleted or anonymized.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">8. Your Choices</h2>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                You can exercise your GDPR rights by:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                <li>Using the privacy controls in your account settings</li>
                <li>Contacting our Data Protection Officer</li>
                <li>Submitting a request through our contact form</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">9. Contact Information</h2>
              <p className="text-gray-600 dark:text-gray-400">
                For any GDPR-related queries or to exercise your rights, please{' '}
                <Link to="/contact" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  contact our Data Protection Officer
                </Link>.
              </p>
            </section>

            <div className="mt-12 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                This GDPR notice is part of our{' '}
                <Link to="/privacy" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  Privacy Policy
                </Link>. For more information about how we handle your data, please review our{' '}
                <Link to="/privacy" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link to="/cookies" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  Cookie Policy
                </Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 